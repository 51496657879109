<template>
  <!-- Right side -->
  <div id="right_side_content">
    <div id="langs">
      <span @click="changeLang('fr')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'fr') }" > <img src="@/assets/images/common/Drapeau-FR.png" alt="Drapeau-FR" width="30px" height="30px"/> </span>
      <span @click="changeLang('en')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'en') }" > <img src="@/assets/images/common/Drapeau-Eng.png" alt="Drapeau-Eng" width="30px" height="30px"/> </span>
    </div>
  </div>
</template>

<script>
import { localize } from 'vee-validate'

export default {
  name: 'RightSide',
  props: {
  },
  data () {
    return {
      debug: false
    }
  },
  mounted () {
  },
  created: function () {
  },
  methods: {
    changeLang (lang) {
      this.$setLang(lang)
      this.$store.state.lang = lang
      localize(lang)
    }
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/global";

#right_side_content {
  height: 100%;
  width: 100%;
  background-image:url("../assets/images/rightside/astronaut.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

#langs {
  padding-top: 20px;
  padding-right: 20px;
  float: right;
}

#langs span {
  padding: 2px 5px 2px 5px;
  cursor: pointer;
}
</style>
