<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='infos'/>
    </div>

    <!-- Main -->
    <div id="main">

        <div class="top-menu">
          <span><strong>{{ $lang.view.EditMyInfo.title }}</strong></span>
          <hr/>
        </div>

        <!-- Mes Infos -->
        <div class="fenetre">
          <div style="display: flex; flex-direction: row">
              <div style="position: relative;">
                <!--account logo-->
                <img v-if="logoImg" ref="logo_img1" style="top: 0px; left: 0px; height: 100px;" :src="logoImg" @load="updatePencilPosition" />
                <img v-else style="position: relative; top: 0px; left: 0px; height: 65px; width: 65px;" src="@/assets/images/common/Icone-mes-infos-blanc.svg" />

                <img v-if="userType == 'AGENCY'" ref="pencil_img" style="position: absolute; top: 40px; left: 40px; height: 25px; width: 25px;" src="@/assets/images/common/Customize-logo.svg"  @click="show_customize_logo = 1" />
              </div>
              <div style="display: flex; flex-direction: column; margin-left: 1em;">
                <div>
                  <p><strong style="text-decoration: underline;">{{ loginInfo }}</strong> <span v-if="this.$store.getters.currentUser && this.$store.getters.currentUser.type === 'LICENCE_USER'">({{ nb_credits }} {{ $lang.view.EditMyInfo.remaining_credits }})</span></p>
                  <p v-on:click="logout()" class="pointer" style="text-decoration: underline;">{{ $lang.view.EditMyInfo.disconnect }}</p>
                </div>
              </div>
          </div>
        </div>

        <!-- Create logo popup -->
        <div v-if="show_customize_logo == 1" class="w3-modal" style="display: block;">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
            <header class="w3-container" style="margin-bottom: 40px;">
              <span @click="show_customize_logo = 0" class="w3-button w3-display-topright">&times;</span>
              <h1 class="modal_header">{{ $lang.view.EditMyInfo.customize }}</h1>
            </header>
            <div style="margin: auto; width: 500px;">
              <div id="file_drop_box" style="flex-grow: 1;">
                <UploadLogoFile v-on:logo-uploaded="getLogoContent(); show_customize_logo = 0;">
                </UploadLogoFile>
              </div>
            </div>
          </div>
        </div>

        <!-- More information -->
        <br>
        <div>
          <div id="more_infos">{{ $lang.view.EditMyInfo.moreInfos }}</div>
          <div id="policy" class="pointer">
            <p id="private_policies" @click="show_privacy=1">{{ $lang.view.EditMyInfo.private_policies }}</p>
            <span>{{ $lang.view.EditMyInfo.import_message }}</span>
          </div>
          <div id="usage" class="pointer">
            <p @click="show_cgu=1">{{ $lang.view.EditMyInfo.terms_of_use }}</p>
          </div>
        </div>

        <!-- Privacy Policy -->
        <div v-if="show_privacy == 1" class="w3-modal" style="display: block; z-index: 100">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top" style="width: 90%;">

            <header class="w3-container" style="margin-bottom: 40px;">
              <span @click="show_privacy=0" class="w3-button w3-display-topright">&times;</span>
              <h3>{{$lang.view.EditMyInfo.private_policies}}</h3>
            </header>

            <PrivatePolicy />

            <br><br>
            <footer class="w3-container w3-center">
              <center>
                <Button style="width: 120px;" v-on:click.native="(show_privacy=0)" :text='$lang.view.EditMyInfo.close' />
              </center>
              <br><br>
            </footer>
          </div>
        </div>

        <!-- CGU -->
        <div v-if="show_cgu == 1" class="w3-modal" style="display: block; z-index: 100">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top" style="width: 90%;">

            <header class="w3-container" style="margin-bottom: 40px;">
              <span @click="show_cgu=0" class="w3-button w3-display-topright">&times;</span>
              <h3>{{$lang.view.EditMyInfo.terms_of_use}}</h3>
            </header>

            <CguAgency v-if="this.$store.getters.currentUser && this.$store.getters.currentUser.type === 'AGENCY'"/>
            <CguClient v-else />

            <br><br>
            <footer class="w3-container w3-center">
              <center>
                <Button style="width: 120px;" v-on:click.native="(show_cgu=0)" :text='$lang.view.EditMyInfo.close' />
              </center>
              <br><br>
            </footer>
          </div>
        </div>

    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import Button from '@/components/Button.vue'
import UploadLogoFile from '@/components/UploadLogoFile.vue'
import CguAgency from '@/components/CguAgency.vue'
import CguClient from '@/components/CguClient.vue'
import PrivatePolicy from '@/components/PrivatePolicy.vue'

export default {
  name: 'EditMyInfo',
  data () {
    return {
      show_privacy: 0,
      show_cgu: 0,
      show_customize_logo: 0,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    Button,
    RightSide,
    UploadLogoFile,
    CguAgency,
    CguClient,
    PrivatePolicy
  },
  created: function () {
  },
  mounted () {
  },
  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    updatePencilPosition () {
      this.$refs.pencil_img.style.top = (this.$refs.logo_img1.offsetHeight - 20) + 'px'
      this.$refs.pencil_img.style.left = (this.$refs.logo_img1.offsetWidth - 20) + 'px'
    },
    getLogoContent () {
      var request = {}

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/agencies/' + this.$store.getters.currentAgency.id + '/getlogofile',
        responseType: 'arraybuffer',
        data: request
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            let contentType = resp.headers['content-type']
            let data = 'data:' + contentType + ';base64,' + Buffer.from(resp.data).toString('base64')
            await this.$store.dispatch('setCurrentAgencyLogo', data)
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    }
  },
  computed: {
    logoImg () {
      return this.$store.getters.userLogo
    },
    loginInfo () {
      return this.$store.getters.userLogin
    },
    nb_credits () {
      return this.$store.getters.currentLicence.credits_counter
    },
    userType () {
      return this.$store.getters.userType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
   font-family: DKMagicalBrush;
   font-weight: normal;
   font-size: 48px;
   letter-spacing: 0.24px;
   line-height: 52px;
   text-align: left;
}

.fenetre {
  color: #093555;
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

#file_drop_box {
  height: 150px;

  border-color: #093555;
  border-style: solid;
  border-radius: 6px;
  background-color: #F7F7F7;
  background-image: url('../assets/images/common/Drop-box.svg');
  background-size: 80px 80px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

#more_infos {
  width: 100%;
  padding-bottom: 10px;
  color: #093555;
  font-family: DKMagicalBrush;
  font-size: 22px;
  letter-spacing: 0.11px;
  line-height: 26px;
  text-align: left;
}

#policy {
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 340px;
  height: 80px;
  float: left;
  margin-right: 2em;
  margin-bottom: 1em;

  padding-top: 0px;
  padding-left: 20px;
  color: #093555;
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

#private_policies {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  color: #093555;
  font-family: Poppins;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

#usage {
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 340px;
  height: 80px;
  float: left;

  padding-top: 0px;
  padding-left: 20px;
  color: #093555;
  font-family: Poppins;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

.modal {
  background-color: #093555;
  border-radius: 20px;
  width: 600px;
  height: 400px;

  color: #FAF5F5;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  text-align: left;
}

.modal_header {
  margin-top: 50px;

  color: #FAF5F5;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.16px;
  line-height: 38px;
  text-align: center;
}

</style>
