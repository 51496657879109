<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $route.params.projectName }}</strong></span>
        <hr>
      </div>

      <div id="fil_ariane">
        <span>
          <router-link v-bind:to="foldersLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_folders }}</router-link>
          <router-link v-bind:to="licenceLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_projects }}</router-link>
          <router-link v-bind:to="editingLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_editing }}</router-link>
          <strong>{{ $route.params.projectName }}</strong>
        </span>
      </div>

      <div id="main_area" style="z-index: 1;">

        <div style="display: flex;">
          <div style="float: left; padding: 10px; width: 50%;">
            <img src="@/assets/images/common/Crayon-de-modification-couleur.svg" style="margin-right: 10px; height: 20px; width:37px; cursor: pointer;" />
            <span><strong>{{ $lang.view.EditingProject.brief }}</strong></span>
            <br/>
            <div style="margin-top: 10px; margin-bottom: 30px;">
              <textarea readonly ref="edit_brief_textarea" v-model="brief_text" :placeholder="$lang.view.EditingProject.brief_placeholder"></textarea>
            </div>
          </div>

          <div style="float: right; display: flex; flex-direction: column; padding: 10px; width: 50%;">
            <div style="margin-bottom: 10px;">
              <img src="@/assets/images/common/Dossier-vert.svg" style="margin-right: 10px; height: 24px; width:29px; cursor: pointer;" />
              <span><strong>{{ $lang.view.EditingProject.contents }} ({{ contents_count }})</strong></span>
            </div>
            <div class="rush" v-for="(rush, index) in rushes_list" :key="index" style="display: flex; align-items: center; padding-left: 10px;">
              <div style="float: left; flex-grow: 1; height: 32px; display: flex; align-items: center; overflow: hidden;">
                <span class="pointer" v-on:click="downloadRushFile(rush.name, rush.rush_file_url, rush.file_url)">{{ rush.name }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <LoadingAnimation v-if="loading" :progressEvent="progressEvent" />

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
  name: 'EditingProject',
  data () {
    return {
      loading: false,
      progressEvent: null,

      error_message: null,
      brief_text: null,
      rushes_list: null,
      contents_count: 0,

      foldersLink: null,
      licenceLink: null,
      editingLink: null,
      editedLink: null,
      validatedLink: null,

      debug: 1
    }
  },
  props: {},
  components: {
    NavigationMenu,
    RightSide,
    LoadingAnimation
  },
  mounted () {
    this.getProjectContents()
  },
  created: function () {
    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`
    this.editingLink = this.licenceLink + '/editing'
    this.editedLink = this.licenceLink + '/edited'
    this.validatedLink = this.licenceLink + '/validated'

    this.brief_text = this.$store.getters.currentProject.brief_text
    this.brief_version = this.$store.getters.currentProject.brief_version
  },
  methods: {
    getProjectContents () {
      var request = {}

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/rusheslist',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.rushes_list = resp.data
            if (resp.data) {
              this.contents_count = resp.data.length
            }
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },
    downloadRushFile (rushname, rushFileUrl, fileUrl) {
      var request = {
        file_url: rushFileUrl
      }

      this.loading = true

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        responseType: 'blob', // arraybuffer
        url: '/projects/' + this.$store.getters.currentProject.id + '/file',
        data: request,
        onDownloadProgress: (progressEvent) => {
          this.progressEvent = progressEvent
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.downloadFile(resp.data, rushname)
          }
        })
        .catch((error) => {
          this.loading = false
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
        .finally(() => {
          this.progressEvent = null
        })
    },
    downloadFile (blob, filename) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        let fileURL = window.URL.createObjectURL(blob)
        let fileLink = document.createElement('a')
        fileLink.setAttribute('target', '_blank')
        fileLink.setAttribute('href', fileURL)
        fileLink.setAttribute('download', filename)
        fileLink.style.visibility = 'hidden'
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
        window.URL.revokeObjectURL(fileURL)
        fileLink.remove()
      }
      this.loading = false
    },
    goToEditLicence () {
      this.show_sent_message = 0

      // Go to the EditLicence view
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      this.$router.push(`/login/${agencyId}/${licenceId}`)
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/global";

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

#fil_ariane {
  margin-bottom: 20px;

  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 19px;
  text-align: left;
}

#main_area {
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 19px;
  text-align: left;
}

.rush {
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  width: 100%;
  height: 52px;

  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

textarea {
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  resize: none;
  width: 100%;
  height: 225px;
}

</style>
