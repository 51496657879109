<template>
  <div id="container" @click="hideErrorPanel">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $route.params.projectName }}</strong></span>
        <hr>
      </div>

      <div id="fil_ariane">
        <span>
          <router-link v-bind:to="foldersLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_folders }}</router-link>
          <router-link v-bind:to="licenceLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_projects }}</router-link>
          <router-link v-bind:to="validatedLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_validated }}</router-link>
          <strong>{{ $route.params.projectName }}</strong>
        </span>
      </div>

      <!-- Video player -->
      <div id="main_area" style="margin-top: 20px;">
        <center>
          <video id="videoPlayer" class="video-js" width="640px" height="360px" controls preload="auto" data-setup="{}">
            <p class="vjs-no-js">
              Enable javascript to view this video
            </p>
          </video>
        </center>
      </div>

      <!-- error message -->
      <div id="page-mask" v-if="error_message"></div>
      <div ref="error_panel" id="error_panel" v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>
    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import 'video.js/dist/video-js.css'
import _videojs from 'video.js'
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'

const videojs = window.videojs || _videojs

export default {
  name: 'PlayVideo',
  data () {
    return {
      project: null,
      player: null,

      foldersLink: null,
      licenceLink: null,
      validatedLink: null,

      error_message: null,
      debug: 1
    }
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    NavigationMenu,
    RightSide
  },
  created: function () {
    this.project = this.$store.getters.currentProject

    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`
    this.validatedLink = this.licenceLink + '/validated'
  },
  async mounted () {
    this.player = videojs('videoPlayer')

    var path = this.project.download_url + '/' + this.project.edited_file_url
    var downloadUrl = path.replace(/[\\]+/g, '/')
    console.log('downloadUrl: ' + downloadUrl)

    this.player.src({ type: 'video/mp4', src: downloadUrl })
    this.player.on('error', (event) => {
      this.error_message = this.$lang.view.PlayVideo.no_video_link
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    hideErrorPanel () {
      this.error_message = null
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
.video-js .vjs-time-control {
  display: block!important;
}
.video-js .vjs-remaining-time {
  display: none!important;
}
</style>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

#fil_ariane {
  margin-bottom: 20px;
  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 19px;
  text-align: left;
}

#main_area {
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 19px;
  text-align: left;
}

#page-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#error_panel {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 600px;
  margin-left: -300px;

  height: 140px;
  margin-top: -70px;
}

</style>
