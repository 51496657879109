var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"container"}},[_c('div',{attrs:{"id":"nav"}},[_c('NavigationMenu',{attrs:{"input_current_menu":"folders"}})],1),_c('div',{attrs:{"id":"main"}},[_c('div',{staticClass:"top-menu"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$lang.view.CreateLicence.title))])]),_c('hr')]),_c('div',[_c('ValidationObserver',{ref:"folder_data_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"w3-container",staticStyle:{"padding":"0px"}},[_c('span',{staticClass:"sub-menu"},[_vm._v(_vm._s(_vm.$lang.view.CreateLicence.name))]),_c('ValidationProvider',{attrs:{"rules":{required: true, regex: _vm.regex, },"name":_vm.$lang.view.CreateLicence.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input-field w3-input",attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('div',{staticClass:"field_error_message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('span',{staticClass:"sub-menu"},[_vm._v(_vm._s(_vm.$lang.view.CreateLicence.email))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":_vm.$lang.view.CreateLicence.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input-field w3-input",attrs:{"type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"field_error_message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('span',{staticClass:"sub-menu"},[_vm._v(_vm._s(_vm.$lang.view.CreateLicence.phone))]),_c('ValidationProvider',{attrs:{"rules":"","name":_vm.$lang.view.CreateLicence.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"input-field w3-input",attrs:{"type":"text"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('div',{staticClass:"field_error_message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_validation_rights),expression:"user_validation_rights"}],attrs:{"type":"checkbox","id":"permission"},domProps:{"checked":Array.isArray(_vm.user_validation_rights)?_vm._i(_vm.user_validation_rights,null)>-1:(_vm.user_validation_rights)},on:{"change":function($event){var $$a=_vm.user_validation_rights,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.user_validation_rights=$$a.concat([$$v]))}else{$$i>-1&&(_vm.user_validation_rights=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.user_validation_rights=$$c}}}}),_c('label',{attrs:{"for":"permission"}}),_c('span',{staticClass:"validation",staticStyle:{"padding-left":"30px"}},[_vm._v(_vm._s(_vm.$lang.view.CreateLicence.validation_msg))]),_c('br'),_c('div',{staticStyle:{"padding-top":"50px","padding-left":"50px"}},[(!invalid && dirty)?_c('Button',{staticClass:"wide",attrs:{"text":_vm.$lang.view.CreateLicence.create,"icon":_vm.plus_sign_img,"clicked_icon":_vm.plus_sign_clicked_img},nativeOn:{"click":function($event){return _vm.createLicence()}}}):_c('Button',{staticClass:"wide inactive",attrs:{"text":_vm.$lang.view.CreateLicence.create,"icon":_vm.plus_sign_img}})],1),(_vm.error_message)?_c('div',{staticClass:"w3-panel w3-red"},[_vm._v(" "+_vm._s(_vm.error_message)+" ")]):_vm._e()]}}])})],1)]),_c('div',{attrs:{"id":"right_side"}},[_c('RightSide')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }