<template>
  <div id="container">

    <!-- Connexion side -->
    <div id="connexion_side_content">
      <img id="connexion_side_image" src="@/assets/images/connexion/fond-home.svg" />
    </div>

    <!-- Login area -->
    <div class="login" style="float: left; flex-grow: 1;">

      <div style="float: right; width: 100%; margin-bottom: 250px;">
        <div id="langs">
          <span @click="changeLang('fr')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'fr') }" > <img src="@/assets/images/common/Drapeau-FR.png" alt="Drapeau-FR" width="30px" height="30px"/> </span>
          <span @click="changeLang('en')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'en') }" > <img src="@/assets/images/common/Drapeau-Eng.png" alt="Drapeau-Eng" width="30px" height="30px"/> </span>
        </div>
      </div>

      <div style="float: right; width: 100%;">
        <div class="message">
          <center>
            <div style="width: 500px; margin-top: 70px;" class="w3-container">
              <div v-html="$lang.view.CheckId.message1"></div><br/>
              <div v-html="$lang.view.CheckId.message2"></div><br/>
              <div v-html="$lang.view.CheckId.message3"></div><br/>
              <Button style="margin-top: 20px; width: 260px;" :text="$lang.view.CheckId.back" to="/login" :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
            </div>
          </center>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { localize } from 'vee-validate'

import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'
import Button from '@/components/Button.vue'

export default {
  name: 'CheckId',
  data () {
    return {
      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage
    }
  },
  mounted () {
  },
  methods: {
    changeLang (lang) {
      this.$setLang(lang)
      this.$store.state.lang = lang
      localize(lang)
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.login {
  background-color: #F5F2EB;
  height: 100%;
  min-width: 800px;
}

.message {
  float: left;
  margin-left: 80px;
  width: 570px;
  height: 300px;

  background-color: #093555;
  border-radius: 3px;

  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}

#langs {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}

#langs span {
  padding: 2px 5px 2px 5px;
  cursor: pointer;
}

#connexion_side_content {
  position: relative;
  overflow: hidden;
  background-color: #093555;

  height: 100%;
  width: 100%;
}

#connexion_side_image {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}

</style>
