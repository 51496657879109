<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $lang.view.EditLicence.title }}</strong></span>
        <hr>
      </div>

      <div style="display: flex;">
        <!-- project category list -->
        <div style="width: 32%; margin-right: 2%;">
          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_created.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="licenceLink" class="routerlink">
                {{ $lang.view.EditLicence.project }}<br/>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_created_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier" style="background-color: #F5BF42; color: #FFFFFF;">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_editing_clicked.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editingLink" class="routerlink">
                {{ $lang.view.EditLicence.editing }} <br>
                <span style="font-size: 16px;">{{ nb_editing_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_edited.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editedLink" class="routerlink">
                {{ $lang.view.EditLicence.edited }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_edited_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_validated.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="validatedLink" class="routerlink">
                {{ $lang.view.EditLicence.validated }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_validated_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

        </div>

        <!-- Editing projects list -->
        <div v-if="this.projects_list.length == 0" class="projects" style="display: flex; align-items: center; justify-content: center; width: 66%;">
          <div style="width: 270px;"><strong>{{ $lang.view.EditLicence.no_editing_project }}</strong></div>
        </div>
        <div v-else class="projects" style="width: 66%;">
          <div style="flex: 1; max-height: 350px; overflow-y: auto;">

            <div class="project" v-for="(project, index) in projects_list" :key="index" style="display: flex; align-items: center; padding-left: 10px;">
              <div style="float: left; flex-grow: 1;">
                <img style="margin-right: 10px;" src="../assets/images/common/Project_editing.svg" width="34px" height="37px" />
                <!--<span class="pointer" v-on:click="selectEditingProject(project)">{{ project.name }}</span>-->
                <span><i>{{ project.name }}</i></span>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- error message -->
      <div v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'

export default {
  name: 'ListEditingProjects',
  data () {
    return {
      projects_list: [],
      foldersLink: null,
      licenceLink: null,
      editingLink: null,
      editedLink: null,
      validatedLink: null,

      error_message: null,
      succes_message: null,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide
  },
  mounted () {
    this.reloadCurrentLicence()
  },
  created: function () {
    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`

    this.editingLink = this.licenceLink + '/editing'
    this.editedLink = this.licenceLink + '/edited'
    this.validatedLink = this.licenceLink + '/validated'

    this.getProjectList()
  },
  methods: {
    getProjectList () {
      var request = {
        licence_id: this.$store.getters.currentLicence.id,
        state: 'editing',
        limit: 500, // TODO
        sort: [['name', 'ASC']]
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/list',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.projects_list = resp.data
            return resp.data
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    reloadCurrentLicence () {
      var licenceId = this.$store.getters.currentLicence.id

      this.$http({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: `/licences/${licenceId}`,
        data: {}
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            var licence = resp.data
            await this.$store.dispatch('setCurrentLicence', licence)
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    async selectEditingProject (project) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      var projectName = project.name

      await this.$store.dispatch('setCurrentProject', project)
      this.$router.push(`/login/${agencyId}/${licenceId}/editing/${projectName}`)
    }
  },
  computed: {
    nb_created_projects () {
      return this.$store.getters.currentLicence.created_project_counter
    },
    nb_editing_projects () {
      return this.$store.getters.currentLicence.editing_project_counter
    },
    nb_edited_projects () {
      return this.$store.getters.currentLicence.edited_project_counter
    },
    nb_validated_projects () {
      return this.$store.getters.currentLicence.validated_project_counter
    },
    nb_credits () {
      return this.$store.getters.currentLicence.credits_counter
    },
    userType () {
      return this.$store.getters.userType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

.dossier {
  display: flex;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  height: 90px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 25px;
  text-align: left;
}

.projects {
  display: flex;
  flex-direction:column;
  margin-left: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  height: 420px;
  width: 100%;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 27px;
  text-align: center;
}

.project {
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 4px;
  width: 100%;
  height: 70px;

  color: #0C4168;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

</style>
