<template>
  <div id="app">

  <!-- Header -->
  <header>
  </header>

  <!-- Error -->
  No config.json file found !

  <!-- Footer -->
  <footer>
  </footer>

  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data () {
    return {
      debug: false
    }
  },
  mounted () {
    console.log('NODE_ENV: ' + process.env.NODE_ENV)
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "./assets/styles/global";

html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 25px;
  background-color: #113356;
  color: white;
}

#app {
}
</style>
