<template>
  <div id="container">

    <!-- Connexion side -->
    <div id="connexion_side_content">
      <img id="connexion_side_image" src="@/assets/images/connexion/fond-home.svg" />
    </div>

    <!-- Login area -->
    <div class="login" style="float: left; flex-grow: 1;">

      <div style="float: right; width: 100%; margin-bottom: 250px;">
        <div id="langs">
          <span @click="changeLang('fr')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'fr') }" > <img src="@/assets/images/common/Drapeau-FR.png" alt="Drapeau-FR" width="30px" height="30px"/> </span>
          <span @click="changeLang('en')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'en') }" > <img src="@/assets/images/common/Drapeau-Eng.png" alt="Drapeau-Eng" width="30px" height="30px"/> </span>
        </div>
      </div>

      <div style="float: right; width: 100%;">
        <div class="message">
          <center>

            <div style="width: 500px; margin-top: 70px;" class="w3-container">
              <div v-if="email_sent">
                {{ $lang.view.LostId.confirmation_message }}
              </div>
              <div v-else>
                <div style="margin-top: 10px;" v-html="$lang.view.LostId.title"></div>
                <div style="margin-top: 10px;" v-html="$lang.view.LostId.email"></div>
                <input style="margin-top: 30px;" class="w3-input" type="text" v-model="input.email" v-bind:placeholder="$lang.view.LostId.email_edit">
                <Button style="margin-top: 60px; width: 260px;" :text='$lang.view.LostId.send' @click.native="send()" :icon="path_right_img" :clicked_icon="path_right_clicked_img" />

                <!-- error message -->
                <div v-if="error_message" class="w3-panel w3-red">
                  {{error_message}}
                </div>

              </div>
            </div>

          </center>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { localize } from 'vee-validate'

import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'
import Button from '@/components/Button.vue'

export default {
  name: 'LostId',
  data () {
    return {
      input: {
        email: ''
      },
      email_sent: false,

      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage,
      error_message: null
    }
  },
  mounted () {
  },
  components: {
    Button
  },
  methods: {
    changeLang (lang) {
      this.$setLang(lang)
      this.$store.state.lang = lang
      localize(lang)
    },
    send () {
      var request = {
        email: this.input.email,
        application_type: 'MaSolutionVideo'
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/users/lostlogin',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.email_sent = true
          } else {
            throw resp
          }
          this.$router.push('/lostid/checkid')
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.error_message = this.$lang.view.LostId.unknown_email
          } else {
            this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.login {
  background-color: #F5F2EB;
  height: 100%;
  min-width: 800px;
}

.message {
  float: left;
  margin-left: 80px;
  width: 570px;
  height: 380px;

  background-color: #093555;
  border-radius: 3px;

  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}

input {
  background-color: #FFFFFF;
  border-radius: 6px;
  width: 420px;
  height: 60px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

#langs {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}

#langs span {
  padding: 2px 5px 2px 5px;
  cursor: pointer;
}

#connexion_side_content {
  position: relative;
  overflow: hidden;
  background-color: #093555;

  height: 100%;
  width: 100%;
}

#connexion_side_image {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}

</style>
