<template>
  <div id="upload_logo_file">
    <FilePond
      name="upload_logo_file_pond"
      ref="pond"
      :label-idle="this.$lang.component.UploadLogoFile.dropLogoMessage"
      credits="false"
      allowMultiple="false"
      v-bind:server="myServer"
      v-bind:files="myFiles"
      v-on:init="handleFilePondInit"
      allowFileSizeValidation="true"
      maxFileSize="50KB"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Create component
const FilePond = vueFilePond(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

export default {
  name: 'UploadLogoFile',
  data: function () {
    return {
      // fake server to simulate loading a 'local' server file and processing a file
      myServer: {
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          const formData = new FormData()
          formData.append(fieldName, file, file.name)

          // related to aborting the request
          const CancelToken = this.$http.CancelToken
          const source = CancelToken.source()

          this.$http({
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (e) {
              // Updating progress indicator
              // Should call the progress method to update the progress to 100% before calling load
              // Setting computable to false switches the loading indicator to infinite mode
              progress(e.lengthComputable, e.loaded, e.total)
            },
            cancelToken: source.token,
            baseURL: this.$store.state.config.webapiurl,
            url: '/agencies/' + this.$store.getters.currentAgency.id + '/putlogofile',
            data: formData
          })
            .then((resp) => {
              // Should call the load method when done and pass the returned server file id
              // this server file id is then used later on when reverting or restoring a file
              // so your server knows which file to return without exposing that info to the client
              if (resp.status === 200) {
                // passing the file id to FilePond
                // the load method accepts either a string (id) or an object
                load(resp.data.file_url)
                this.$emit('logo-uploaded')
              } else {
                // Can call the error method if something is wrong, should exit after
                error(resp.response.data.message)
              }
            })
            .catch((thrown) => {
              if (this.$http.isCancel(thrown)) {
                // TODO effacer le fichier avec la webapi
              } else {
                if (thrown.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                } else if (thrown.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                } else {
                  // Something happened in setting up the request that triggered an Error
                }
                error('Erreur survenue pendant le transfert')
              }
            })

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              source.cancel(this.$lang.component.UploadLogoFile.cancelMessage)

              // Let FilePond know the request has been cancelled
              abort()
            }
          }
        }
      },
      myFiles: null
    //   [
    //     {
    //       source: 'photo.jpeg',
    //       options: {
    //         type: 'local'
    //       }
    //     }
    //   ]
    }
  },
  methods: {
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`
    }
  },
  components: {
    FilePond
  }
}
</script>

<style lang="scss">
.filepond--panel-root {
    border-radius: 0em;
    background-color:transparent;
}
</style>
