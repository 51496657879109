<template>
  <div id="privatePolicy">

<p class=Standard align=center style='text-align:center'><b><span style='color:rgb(11,83,148);'>Politique de gestion des données personnelles et de protection de la vie privée</span></b></p>

<p class=Standard><span style='font-size:9.0pt;'>Dans le cadre de notre activité de production de vidéos, des applications associées, et
des services annexes, nous sommes amenés à collecter certaines données à votre sujet.
Conformément <a href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32016R0679" target="_blank">au règlement européen 2016/679</a>, dit « <a href="https://fr.wikipedia.org/wiki/R%C3%A8glement_g%C3%A9n%C3%A9ral_sur_la_protection_des_donn%C3%A9es" target="_blank">règlement général sur la protection des données</a> », la présente politique vous informe sur :
<ol>
<li>Les principes encadrant la collecte de ces données</li>
<li>Collecte des données</li>
<li>Engagement sur la sécurité de la vie privée</li>
<li>Utilisation des données personnelles</li>
<li>Droit d’accès</li>
<li>Sécurité et Confidentialité</li>
<li>Données anonymes</li>
<li>Diffusion à des destinataires non intéressés</li>
<li>Informations personnelles nominatives et enfants</li>
<li>Relations avec d’autres sites</li>
<li>Contact chez Tydeo</li>
<li>Dispositions Générales</li>
</ol>
</span></p>
<p class=Standard style='text-align:justify;text-justify:inter-ideograph;'><b><span style='font-size:9.0pt;color:black'>
L’Application My Tydeo est éditée par la société Tydeo, SAS au capital de 107 456 €, immatriculée au RCS de Brest sous le n° 797 881 125 000 26, dont le siège social est situé au 1, rue des Ateliers 29290 Saint Renan (France).<br/>
Le directeur de la publication de Tydeo et de son Application My Tydeo est Julien Cabon.<br/>
Pour nous contacter, veuillez nous adresser un mail à l’adresse suivante : <a href="mailto:info@tydeo.fr">info@tydeo.fr</a>.<br/>
</span></b>
</p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 1 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Principes encadrant la collecte des données</span></b></p>
<p class=Standard style='text-align:justify;text-justify:inter-ideograph'><span style='font-size:9.0pt;color:black'>
En publiant cette politique, nous définissons plusieurs objectifs :
<ul>
  <li>la protection de la vie privée adaptée au visiteur ou à l’utilisateur individuel de « My Tydeo » (ci-après l’ « Application »).</li>
  <li>la confiance, qui impose que nous détaillons clairement la manière dont nous collectons ces données ;</li>
  <li>la transparence, qui demande que nous expliquions pourquoi et comment nous traitons ces données, au-delà même des exigences de la législation ;</li>
  <li>la sécurité, qui ne passe pas seulement par le chiffrement, mais aussi et surtout par la réduction de l’étendue de la collecte des données au strict nécessaire, et l’élaboration de processus internes d’accès aux données.</li>
</ul>
</span></p>
<p class=Standard style='text-align:justify;text-justify:inter-ideograph'><span style='font-size:9.0pt;color:black'>
Conformément à la législation en vigueur, nous collectons des données :
<ul>
  <li>avec votre consentement, exprimé lors de votre abonnement, et que vous pouvez modifier à tout moment ;</li>
  <li>dans votre intérêt, la fourniture de certains services comme les espaces de commentaires ou les informations et tutoriels nécessitant le traitement de données personnelles ;</li>
  <li>et dans notre intérêt légitime, le financement de la création d’une information spécialisée de qualité, la préservation de notre indépendance, le développement de nos offres d’abonnement.</li>
</ul>
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 2 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Collecte des données</span></b></p>
<p class=Standard><span style='font-size:9.0pt;'>Lors de votre abonnement, nous créons un compte relatif à votre offre. Pour créer un compte,
vous devez nous communiquer les données suivantes :
<ul>
<li>votre pseudonyme ;</li>
<li>un mot de passe ;</li>
<li>votre adresse de courrier électronique.</li>
</ul>
</span></p>
<p class=Standard><span style='font-size:9.0pt;'>Votre pseudonyme est public, visible par les autres utilisateurs de votre compte.
Votre adresse de courrier électronique est masquée, mais les autres utilisateurs de votre compte seront notifiés des
actions et échanges avec les équipes My Tydeo. Votre mot de passe est chiffré de telle manière qu’il nous est impossible
de le consulter. Toutes ces données sont conservées tant que vous êtes inscrit, comme la date de création de votre compte.
Lorsque vous vous connectez à l’Application, nous associons votre device à votre identifiant de session, afin de prévenir
certains risques de sécurité. Lorsque votre session expire, nous supprimons votre device.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 3 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Engagement sur la sécurité de la vie privée</span></b></p>
<p class=Standard><span style='font-size:9.0pt;'>Le terme « données à caractère personnel » désigne les informations
  qui vous identifient telles que vos nom, date de naissance, adresse e-mail, adresse postale.
Dans le cadre de l’utilisation de ces données, nous nous engageons à respecter la réglementation
en vigueur concernant les conditions de protection de la vie privée. Les chapitres suivants exposent
les modalités de recueil de vos données personnelles.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 4 :</span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Utilisation des données personnelles</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Tydeo utilise les données à caractère personnel à des fins spécifiques et limitées dont
nous vous informons quand nous vous demandons des informations. Par exemple, nous pouvons collecter et
utiliser les données personnelles pour vous proposer des services susceptibles de vous intéresser à titre
personnel ou pour communiquer avec vous sur d’autres sujets. Tydeo conserve vos données aussi longtemps
que nécessaire et dans le respect de la réglementation applicable en matière de stockage à des fins de
reporting ou de documentation.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 5 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Droit d’accès</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Conformément à la Loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
aux libertés, telle que modifiée par la loi 2004-801 du 6 août 2004, vous disposez du droit d’accès et
de mise à jour de vos données personnelles nominatives ainsi que du droit de demander leur suppression.
De même, vous disposez d’un droit de vous opposer au traitement par Tydeo des données à caractère
personnel vous concernant.</span></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Si vous souhaitez accéder à vos données personnelles ou les corriger ou les supprimer, merci
de bien vouloir nous contacter par mail à l’adresse suivante : <a href="mailto:info@tydeo.fr">info@tydeo.fr</a>.
Vos demandes seront traitées au plus vite. Les demandes de suppression des données personnelles seront soumises
aux exigences qui s’imposent à Tydeo aux plans juridique et éthique, dans les domaines du reporting, de l’archivage
et du stockage de documents.
</span></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Soyez conscient que le droit à l’effacement de vos données personnelles n’est pas un droit à
l’effacement des créations réalisées dans le cadre de l’utilisation de nos services, conformément au cadre
légal et à nos CGV.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 6 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Sécurité et Confidentialité</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Nous mettons tout en œuvre pour assurer la sécurité de vos données. Les communications
avec notre site et notre application <a href="https://en.wikipedia.org/wiki/HTTPS">sont chiffrées</a>.
L’accès aux données personnelles nominatives est limité aux collaborateurs qui ont besoin d’utiliser
ces données et qui ont reçu la formation nécessaire pour les « gérer » correctement et observer
strictement les standards de confidentialité.
</span></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>
Bien que nous ne puissions garantir l’absence de perte, d’usage non autorisé ou d’altération des
données, nous faisons tout notre possible pour éviter que surviennent de telles situations. Au cas
où nous serions victimes d’une faille ou d’une perturbation compromettant la sécurité de vos données
personnelles, nous nous engageons à informer la CNIL dans les conditions décrites
<a href="http://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32016R0679#d1e3479-1-1" target="_blank">par l’article 33 du règlement européen 2016/679</a>.
Nous vous informerons, ainsi que nos partenaires concernés le cas échéant, dans un délai de 24 heures
en suivant les conditions décrites <a href="http://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32016R0679#d1e3479-1-1" target="_blank"></a>par l’article 34.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 7 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Données anonymes</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>La plupart des informations que Tydeo recueille sur l’Application sont des informations
anonymes, telles que les pages que vous visitez et les recherches que vous entreprenez. Lorsque vous
visitez notre aApplication, nous ne recueillons aucune donnée personnelle nominative de votre part, à
moins que vous nous ayez autorisé à le faire. Tydeo utilise des informations anonymes afin d’aider à
améliorer le contenu de son site et d’obtenir des statistiques globales sur ses utilisateurs à des
fins internes et de marketing.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 8 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Diffusion à des destinataires non intéressés</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Tydeo n’accepte pas la diffusion à des destinataires non intéressés. Une telle diffusion
correspond à l’envoi d’e-mails non demandés, généralement de nature commerciale, en nombre important
et de manière répétée aux personnes avec lesquelles l’émetteur n’a pas eu de relations auparavant ou
qui ont refusé de recevoir de tels messages.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 9 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Informations personnelles nominatives et enfants</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Tydeo s’engage à ne pas recueillir, utiliser, ni divulguer en connaissance de cause des
données personnelles nominatives de mineurs de moins de 18 ans.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 10 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Relations avec d’autres sites</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>L’engagement de respect de la vie privée s’applique seulement à l’Application.
Tydeo peut assurer des liaisons avec d’autres sites ou applications dont elle pense qu’ils
présentent un intérêt pour ses visiteurs. Nous cherchons à nous assurer que ces sites et applications
respectent les standards les plus rigoureux. Cependant, en raison de la nature même du réseau mondial,
Tydeo ne peut pas garantir le niveau des standards de chacune des liaisons qu’elle fournit, ni être
responsable du contenu des sites et applications des tiers.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 11 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Contact chez Tydeo</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Si vous souhaitez poser des questions ou émettre des réclamations
relatives au respect de la protection de la vie privée par Tydeo , ou si vous voulez émettre
des recommandations ou faire des commentaires pour améliorer la qualité de notre charte
relative à la protection de la vie privée, n’hésitez pas à nous contacter par e-mail à <a href="mailto:info@tydeo.fr">info@tydeo.fr</a>.
</span></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>La présente charte relative à la protection de la vie privée est applicable au 1er janvier 2020 et
sera mise à jour régulièrement. Nous cherchons à améliorer constamment les outils mis à votre
disposition pour traiter les informations que nous vous fournissons.
</span></p>

<p class=Standard style='text-align:justify;text-justify:inter-ideograph;margin-top: 30px;'><b><span style='font-size:9.0pt;color:black'>ARTICLE 12 : </span></b><b><span style='font-size:9.0pt; color:rgb(11,83,148);'>Dispositions Générales</span></b></p>
<p class=Standard><span style='font-size:9.0pt;
color:black'>Tydeo se réserve le droit de modifier ou de supprimer à tout moment,
et à son entière discrétion, les dispositions de la présente charte. En continuant à utiliser
l’Application, l’utilisateur accepte les modifications qui seraient ainsi intervenues. Il
est en conséquence recommandé à l’utilisateur de consulter régulièrement la présente charte
afin de prendre connaissance de sa mise à jour.
</span></p>

</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PrivatePolicy',
  data () {
    return {
    }
  },
  props: {
  },
  components: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

/* Style Definitions */
p.Standard, li.Standard, div.Standard
{
margin-left: 10px;
margin-right: 10px;
margin-bottom: 5px;
font-size: 12.0pt;
line-height: normal;
}
/* List Definitions */
ol
{margin-bottom: 0cm;}
ul
{margin-bottom: 0cm;}

</style>
