// messages.js
module.exports = {

  view: {

    Login: {
      title: 'MY TYDEO WORKSPACE',
      welcome1: '<strong>Welcome to your personal space !</strong>',
      welcome2: 'You need <strong>to login to get access</strong> to your documents and videos.',
      login: 'Login',
      password: 'Password',
      connection: 'Enter',
      wrong_access: 'You do not have access privilege for this service',
      no_agency: 'Your licence does not belong to any agency',
      login_error: 'Bad login or password',
      lost_password: 'lost password',
      lost_login: 'lost login',
      accept_message: 'By continuing to browse this site, you agree to the use of cookies for videos edition...',
      accept_button: 'I accept',
      close: 'Close',
      invalid_token: 'Session expired, please log in',
      not_registered_yet: 'Not registered ?',
      discover: 'Discover My Tydeo !'
    },

    Cgu: {
      refuse: 'Refuse',
      accept: 'Accept'
    },

    InitPassword: {
      title: 'Enter your <strong>password</strong>',
      password: 'Password',
      back: 'Back',
      send: 'Send',
      new_password: 'new password',
      confirm_password: 'Confirm password',
      passwords_not_equal: 'Passwords are not the same',
      confirmation_message_created: 'Your password has been created',
      confirmation_message_updated: 'Your password has been changed',
      token_expired_message1: 'You cannot reset your password (delay expired).',
      token_expired_message2: 'You can renew your password.',
      visit: 'Or visit your ',
      web: 'web platform',
      download_app: 'Download the mobile app',
      edit: 'to edit'
    },

    Resetpassword: {
      title: 'Change password',
      password: 'Password',
      confirm_password: 'Confirm password',
      send: 'Send',
      edit: 'to edit'
    },

    LostPassword: {
      title: 'Have you lost your <strong>password</strong> ?',
      login: 'Please write your <strong>login</strong>',
      login_edit: 'account\'s login',
      confirmation_message: 'An email has been sent with a link to set your password',
      unknown_login: 'No account associated with this login',
      send: 'Send'
    },

    CheckPassword: {
      message1: 'Please check your <strong>mailbox</strong>',
      message2: 'You will receive a link to re-init your <strong>password</strong>',
      message3: '(Check your spams)',
      back: 'Back'
    },

    LostId: {
      title: 'You have lost <strong>your login</strong> ?',
      email: 'Please enter your e-mail',
      email_edit: 'account\'s email',
      confirmation_message: 'An email has been sent with all your logins',
      unknown_email: 'No account associated with this email',
      send: 'Send'
    },

    CheckId: {
      message1: 'Please check your <strong>mailbox</strong>',
      message2: 'You will receive your <strong>login</strong>',
      message3: '(Check your <strong>SPAMs</strong>)',
      back: 'Back to website'
    },

    EditMyInfo: {
      title: 'Info',
      customize: 'Customize',
      account: 'Account',
      policy: 'Privacy policy',
      accountname: 'Account name',
      terms_of_use: 'Terms of use',
      private_policies: 'Privacy policy',
      import_message: 'Important for you and for us',
      remaining_credits: 'remaining credits',
      close: 'Close',
      disconnect: 'Disconnect',
      moreInfos: 'More information'
    },

    Tutos: {
      title: 'Resources',
      download: 'Download the file',
      copy_to_clipboard: 'Copy the link to the clipboard'
    },

    ListLicences: {
      new: 'New user',
      title: 'My folders',
      name: 'User',
      no_licence: 'You have no licence yet',
      nb_project: 'Projects',
      nb_editing: 'Editing in progress',
      nb_edited: 'Edited Videos',
      nb_validated: 'Approved videos',
      nb_credits: 'Credits',
      create_folder: 'Create new folder',
      delete_warning: 'Are you sure to delete ',
      delete_warning0: 'Are you sure you want to delete the folder ',
      delete_warning1: 'This action is irreversible.',
      yes: 'Yes',
      no: 'No',
      warning: 'Warning'
    },

    CreateLicence: {
      title: 'Create a user account',
      name: 'Username',
      email: 'Email Address',
      phone: 'Phone Number',
      validation_msg: 'Right of Approval',
      create: 'Create user'
    },

    EditLicence: {
      title: 'My projects',
      project: 'Projects',
      editing: 'Editing in progress',
      edited: 'Edited videos',
      validated: 'Approved videos',
      login_header: 'Login',
      email_header: 'E-mail',
      rights_header: 'Validation rights',
      delete_warning0: 'Do you want to delete the project ',
      delete_warning1: 'This is unrecovarable !',
      yes: 'Yes',
      no: 'No',
      warning: 'Warning',
      new_project: 'New project',
      no_project: 'You have no projects yet',
      no_editing_project: 'You have no projects being edited yet',
      no_edited_project: 'You have no edited projects yet',
      no_validated_project: 'You have no approved projects yet',

      new_project_title: 'New Project',
      projectname: 'Project name',
      create_project: 'Create project',
      placeholder: 'Type a name for the new project'
    },

    ListValidatedProjects: {
      title: 'Approved videos',
      name: 'project name',
      play: 'Play video',
      download: 'Download the approved video',
      copy_to_clipboard: 'Copy the link to the clipboard',
      downloading: 'Downloading started',
      copy_done: 'The link of your video has been copied'
    },

    EditProject: {
      my_folders: 'My folders',
      my_projects: 'My projects',
      my_editing: 'Editing in progress',
      my_edited: 'Edited videos',
      my_validated: 'Approved videos',
      brief: 'Brief us',
      contents: 'Contents',
      add_elements: 'Add items',
      update: 'Modify',
      brief_placeholder: 'notices for your edited project...',
      brief_saved: 'brief saved',
      brief_dirty: 'Warning, your brief has not been saved.\nClick on \'Cancel\' then \'save\' to update it.\nOtherwise click on \'OK\'.',
      information: 'Send my project to edit',
      sent_confirmed: 'Your project has been sent.',
      ok: 'Ok',
      cancel: 'Cancel',
      before_sending: 'Are you sure you included all assets before sending ? Once sent to editing, you will not be able to add content to this project.',
      save_brief: 'Save',
      send_project: 'Send to edit',
      before_edit_rush_name_information: 'Rename content',
      before_edit_rush_name_message: 'Type in new content name and click OK.',
      before_delete_rush_information: 'Delete content',
      before_delete_rush_message: 'Are you sure you want to delete this content',
      empty_file: 'Error : empty file',
      brief_brief: 'Provide here all the information needed to edit your project, including texts, keywords, figures and other elements you wish to include in your video.',
      contents_brief: 'Please put your files here'
    },

    EditingProject: {
      brief: 'brief',
      contents: 'contents',
      update: 'Modify',
      brief_placeholder: 'notices for your edited project...'
    },

    ValidateVideo: {
      title: 'to validate',
      brief_placeholder: 'Fill in the changes here',
      warning: 'Almost there ...',
      validate: 'Validate video',
      validate_warning: 'Do you confirm the validation of the project ?',
      yes: 'Yes',
      no: 'No',
      modify_project: 'Ask for a new version',
      information: 'Information',
      ok: 'Ok',
      modification_confirmed: 'Your request has been registered. We treat it as soon as possible.',
      send: 'Send changes',
      your_request: 'Your request',
      success: 'Success',
      validate_success: 'Your video is validated. You can download it from now on.',
      public_link: 'Create a public watching link',
      public_link_copied: 'Link copied to clipboard'
    },

    PlayVideo: {
      title: 'Play video',
      no_video_link: 'The video has been sent one month ago, the link has expired. If you need to get the video again, please contact your MyTydeo representative.'
    }

  },

  component: {

    NavigationMenu: {
      folders: 'My folders',
      tutos: 'Resources',
      infos: 'Info',
      garbage: 'garbage',
      logout: 'logout'
    },

    UploadRushFiles: {
      cancelMessage: 'Upload canceled by the user',
      dropFilesMessage: 'Drop or <span class="filepond--label-action">import</span> your files here (documents, images, logos, videos, sounds)'
    },

    UploadLogoFile: {
      cancelMessage: 'Upload canceled by the user',
      dropLogoMessage: 'Drop or <span class="filepond--label-action">import</span> your logo (png or jpeg format : Max 50 KB)'
    },

    LoadingAnimation: {
      creating: 'Creating file ...'
    }

  }
}
