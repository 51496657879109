<!--
  https://pqina.nl/filepond/docs/api/instance/properties/
  https://pqina.nl/filepond/docs/api/exports/#setting-initial-files
  https://pqina.nl/filepond/docs/api/file-item/
  https://pqina.nl/filepond/docs/api/plugins/file-rename/
-->
<template>
  <div class="upload_rush_files">
    <FilePond
      name="upload_rush_files_file_pond"
      ref="pond"

      credits="false"
      allowMultiple="true"
      allowBrowse="true"
      allowDirectoriesOnly="false"
      allowImagePreview="false"
      allowFileRename="true"

      v-bind:label-idle="this.$lang.component.UploadRushFiles.dropFilesMessage"
      v-bind:server="myServer"
      v-bind:files="myFiles"

      @init="handleFilePondInit"
      @warning="handleWarning"
      @error="handleError"
      @addfile="handleAddFile"
      @addfilestart="handleAddFileStart"
      @addfileprogress="handleAddFileProgress"
      @processfilestart="handleProcessFileStart"
      @processfileprogress="handleProcessFileProgress"
      @processfileabort="handleProcessFileAbort"
      @processfilerevert="handleProcessFileRevert"
      @processfile="handleProcessFile"
      @processfiles="handleProcessFiles"
      @removefile="handleRemoveFile"
      @preparefile="handlePrepareFile"
      @activatefile="handleActivateFile"
      @updatefiles="handleUpdateFiles"
      @initfile="handleInitFile"
      @reorderfiles="handleReorderFiles"

      v-bind:fileRenameFunction="handleFileRename"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileRename from '@/filepond-plugin-file-rename'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileRename
)

export default {
  name: 'UploadRushFiles',
  data: function () {
    return {
      // fake server to simulate loading a 'local' server file and processing a file
      myServer: {
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          console.log(`Uploading file ${file.name}`)
          const formData = new FormData()
          formData.append(fieldName, file, file.name)

          // related to aborting the request
          const CancelToken = this.$http.CancelToken
          const source = CancelToken.source()

          this.$emit('uploadStart')

          this.$http({
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (e) {
              // Updating progress indicator
              // Should call the progress method to update the progress to 100% before calling load
              // Setting computable to false switches the loading indicator to infinite mode
              progress(e.lengthComputable, e.loaded, e.total)
            },
            cancelToken: source.token,
            baseURL: this.$store.state.config.webapiurl,
            url: '/projects/' + this.$store.getters.currentProject.id + '/rushfile?filepath=' + encodeURIComponent(file.name),
            data: formData
          })
            .then((resp) => {
              // Should call the load method when done and pass the returned server file id
              // this server file id is then used later on when reverting or restoring a file
              // so your server knows which file to return without exposing that info to the client
              if (resp.status === 200) {
                // passing the file id to FilePond
                // the load method accepts either a string (id) or an object
                load(resp.data.file_url)
                this.$emit('file-uploaded')
              } else {
                // Can call the error method if something is wrong, should exit after
                error(resp.response.data.message)
              }
            })
            .catch((thrown) => {
              if (thrown.response.data.message === 'Empty file upload not allowed.') {
                this.$emit('emptyFile')
              }
              if (this.$http.isCancel(thrown)) {
                // TODO effacer le fichier avec la webapi
              } else {
                if (thrown.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                } else if (thrown.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                } else {
                  // Something happened in setting up the request that triggered an Error
                }
                error('Erreur survenue pendant le transfert')
              }
            })
            .finally(() => {
              this.$emit('uploadEnd')
            })

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              source.cancel(this.$lang.component.UploadRushFiles.cancelMessage)

              // Let FilePond know the request has been cancelled
              abort()
            }
          }
        }
      },
      myFiles: null
    //   [
    //     {
    //       source: 'photo.jpeg',
    //       options: {
    //         type: 'local'
    //       }
    //     }
    //   ]
    }
  },
  mounted () {
  },
  methods: {
    handleFilePondInit: () => {
      console.log('FilePond has initialized')
      // FilePond instance methods are available on `this.$refs.pond`
      // this.$refs.pond.getFiles()
    },
    handleAddFile: (error, file) => {
      if (error) {
        console.error('Error: ' + error)
        return
      }
      console.log(`File ${file.filename} added`)

      // console.log('File fileExtension', file.fileExtension)
      // console.log('File fileSize', file.fileSize)
      // console.log('File fileType', file.fileType)
      // console.log('File filename', file.filename)
      // console.log('File filenameWithoutExtension', file.filenameWithoutExtension)
      // console.log('File id', file.id)
      // console.log('File origin', file.origin)
      // console.log('File source', file.source)
      // console.log('File relativePath', file.relativePath)
      // console.log('File serverId', file.serverId)
      // console.log('File status', file.status)
      // console.log('File transferId', file.transferId)
    },
    handleFileRename: (file) => {
      if (file._file._relativePath) return `${file._file._relativePath}`
      else return `${file.name}`
    },
    handleWarning: (_error, file, status) => {},
    handleError: (_error, file, status) => {},
    handleAddFileStart: (file) => {},
    handleAddFileProgress: (file, progress) => {},
    handleProcessFileStart: (file) => {},
    handleProcessFileProgress: (file, progress) => {},
    handleProcessFileAbort: (file) => {},
    handleProcessFileRevert: (file) => {},
    handleProcessFile: (_error, file) => {},
    handleProcessFiles: () => {},
    handleRemoveFile: (_error, file) => {},
    handlePrepareFile: (file, output) => {},
    handleActivateFile: (file) => {},
    handleUpdateFiles: (files) => {},
    handleInitFile: (file) => {},
    handleReorderFiles: (files, origin, target) => {}
  },
  components: {
    FilePond
  }
}
</script>

<style lang="scss">
.upload_rush_files {
  width: 100%;
}

.filepond--root {
  width: 100%;
  height: 100%;
}

.filepond--panel-root {
  border-radius: 0em;
  background-color: transparent;
}

.filepond--drop-label {
  cursor: pointer;
  width: 100%;
  height: 120px;
}

.filepond--drop-label label {
  cursor: pointer;
}

.filepond--file-action-button {
  cursor: pointer;
}

</style>
