<template>
  <div id="container" @click="hideErrorPanel">

    <!-- Connexion side -->
    <div id="connexion_side_content">
      <img id="connexion_side_image" src="@/assets/images/connexion/fond-home.svg" />
    </div>

    <!-- Login area -->
    <div class="login" style="float: left; flex-grow: 1; height: 100%; min-width: 700px;">

      <div style="float: right; width: 100%; margin-bottom: 100px;">
        <div id="langs">
          <span @click="changeLang('fr')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'fr') }" > <img src="@/assets/images/common/Drapeau-FR.png" alt="Drapeau-FR" width="30px" height="30px"/> </span>
          <span @click="changeLang('en')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'en') }" > <img src="@/assets/images/common/Drapeau-Eng.png" alt="Drapeau-Eng" width="30px" height="30px"/> </span>
        </div>
      </div>

      <div style="float: right; width: 100%;">
        <div style="float: left; width: 600px;">
          <div style="margin-bottom: 50px;">
            <center><div class="title" v-html="$lang.view.Login.title"></div></center>
            <center><div class="welcome" v-html="$lang.view.Login.welcome1"></div></center>
            <center><div class="welcome" style="width: 480px;" v-html="$lang.view.Login.welcome2"></div></center>
          </div>

          <div style="margin-left: 80px;">
            <div class="sub_title">{{ $lang.view.Login.login }}</div>
            <div class="input"><input @keyup.enter="login" class="w3-input" type="text" v-model="input.login"></div>
            <div class="missing"><router-link to="/lostid" class="routerlink">{{ $lang.view.Login.lost_login }} </router-link></div>
            <br>
            <div class="sub_title">{{ $lang.view.Login.password }}</div>
            <div class="input"><input @keyup.enter="password" class="w3-input" type="password" v-model="input.password"></div>
            <div class="missing"><router-link to="/lostpassword" class="routerlink">{{ $lang.view.Login.lost_password }}</router-link></div>
          </div>
          <div>
            <Button style="margin-top: 40px; margin-left: auto; margin-right: auto; width: 260px;" class='wide' v-on:click.native="login" :text='$lang.view.Login.connection' :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
          </div>

          <!-- error message -->
          <div id="page-mask" v-if="error_message"></div>
          <div ref="error_panel" id="error_panel" v-if="error_message" class="w3-center w3-panel w3-red">
            {{error_message}}
          </div>

          <div class="registered" style="margin-top: 80px;">
            {{ $lang.view.Login.not_registered_yet }}
          </div>
          <div class="discover">
            <a v-if="this.$store.state.lang == 'en'" href="https://en.tydeo.fr/my-tydeo-presentation" target="_blank">{{ $lang.view.Login.discover }}</a>
            <a v-else href="https://www.tydeo.fr/my-tydeo-presentation" target="_blank">{{ $lang.view.Login.discover }}</a>
          </div>

        </div>
      </div>
      <LoadingAnimation v-if="loading" />

    </div>

  </div>
</template>

<script>
import { localize } from 'vee-validate'

// @ is an alias to /src
import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'
import Button from '@/components/Button.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
  name: 'Login',
  data () {
    return {
      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage,

      loading: false,
      input: {
        login: '',
        password: ''
      },
      cookie_accepted: false,

      error_message: null,
      debug: 1
    }
  },
  props: {
  },
  components: {
    Button,
    LoadingAnimation
  },
  created () {
  },
  mounted () {
    // If local storage of browser contain user data we log the user automatically
    if (this.$store.state.token !== null && this.$store.state.token !== '') this.loginToken()
  },
  methods: {
    changeLang (lang) {
      this.$setLang(lang)
      this.$store.state.lang = lang
      localize(lang)
    },
    login () {
      this.loading = true
      this.$store.dispatch('login', { login: this.input.login, password: this.input.password })
        .then(async (resp) => {
          this.$setLang(this.$store.state.user.lang)
          if (this.$store.getters.currentUser.type === 'LICENCE_USER') {
            await this.getUserLicence(this.$store.getters.currentUser.licence_id)
            await this.getUserAgency(this.$store.getters.currentUser.agency_user_id)

            if (!this.$store.getters.currentAgency) {
              this.error_message = this.$lang.view.Login.no_agency
              this.loading = false
              return
            }
          } else if (this.$store.getters.currentUser.type === 'AGENCY') {
            await this.$store.dispatch('setCurrentAgency', this.$store.getters.currentUser)
          } else {
            this.error_message = this.$lang.view.Login.wrong_access
            this.loading = false
            return
          }
          await this.getAgencyLogo()
          this.$router.push('/cgu')
          this.loading = false
        })
        .catch((error) => {
          if (error) this.error_message = this.$lang.view.Login.login_error // + ' - Error ' + error.response.status + ': ' + error.response.data.message
          this.loading = false
        })
    },
    loginToken () {
      this.loading = true
      this.$store.dispatch('loginToken', { login: this.$store.state.token })
        .then(async (resp) => {
          if (this.$store.getters.currentUser.type === 'LICENCE_USER') {
            await this.getUserLicence(this.$store.getters.currentUser.licence_id)
            await this.getUserAgency(this.$store.getters.currentLicence.agency_user_id)

            if (!this.$store.getters.currentAgency) {
              this.error_message = this.$lang.view.Login.no_agency
              this.loading = false
              return
            }
          } else if (this.$store.getters.currentUser.type === 'AGENCY') {
            await this.$store.dispatch('setCurrentAgency', this.$store.getters.currentUser)
          } else {
            this.loading = false
            this.error_message = this.$lang.view.Login.invalid_token
            return
          }
          await this.getAgencyLogo()
          this.$router.push('/cgu')
          this.loading = false
        })
        .catch((error) => {
          if (error) this.error_message = this.$lang.view.Login.invalid_token // + ' - Error ' + error.response.status + ': ' + error.response.data.message
          this.loading = false
        })
    },
    async getUserAgency (agencyId) {
      if (!agencyId) return

      try {
        var resp = await this.$http({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: `/users/${agencyId}`,
          data: {}
        })

        if (resp.status === 200) {
          var user = resp.data
          await this.$store.dispatch('setCurrentAgency', user)
        }
      } catch (error) {
        if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
      }
    },
    async getUserLicence (licenceId) {
      if (!licenceId) return

      try {
        var resp = await this.$http({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: `/licences/${licenceId}`,
          data: {}
        })

        if (resp.status === 200) {
          var licence = resp.data
          await this.$store.dispatch('setCurrentLicence', licence)
        }
      } catch (error) {
        if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
      }
    },
    async getAgencyLogo () {
      if (!this.$store.getters.currentAgency) return
      await this.$store.dispatch('setCurrentAgencyLogo', null)

      // Get agency logo if available
      try {
        var resp = await this.$http({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: '/agencies/' + this.$store.getters.currentAgency.id + '/getlogofile',
          responseType: 'arraybuffer',
          data: {}
        })

        if (resp.status === 200) {
          let contentType = resp.headers['content-type']
          let data = 'data:' + contentType + ';base64,' + Buffer.from(resp.data).toString('base64')
          await this.$store.dispatch('setCurrentAgencyLogo', data)
        }
      } catch (error) {
        if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
      }
    },

    acceptCookie () {
      this.cookie_accepted = true
    },

    hideErrorPanel () {
      this.error_message = null
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.login {
  background-color: #F5F2EB;
  height: 100%;
  min-width: 600px;
}

.title {
  margin-bottom: 20px;

  color: #093555;
  font-family: DKMagicalBrush;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 58px;
  text-align: center;
}

.welcome {
  color: #0C4168;
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0.1px;
  line-height: 26px;
  text-align: center;
}

.sub_title {
  margin-bottom: 10px;
  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

.input {
  width: 430px;
  height: 76px;
}

input {
  border-radius: 8px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 50px;
  text-align: left;
}

.missing {
  width: 430px;

  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: right;
}

.registered {
  color: #009CCA;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 26px;
  text-align: center;
}

.discover {
  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 26px;
  text-align: center;
}

.routerlink {
    text-decoration: underline;
}

#langs {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}

#langs span {
  padding: 2px 5px 2px 5px;
  cursor: pointer;
}

#page-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#error_panel {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 400px;
  margin-left: -200px;

  height: 120px;
  margin-top: -60px;
}

#connexion_side_content {
  position: relative;
  overflow: hidden;
  background-color: #093555;

  height: 100%;
  width: 100%;
}

#connexion_side_image {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}

</style>
