<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $lang.view.EditLicence.title }}</strong></span>
        <hr>
      </div>

      <div style="display: flex; width: 100%;">
        <!-- project category list -->
        <div style="width: 32%; margin-right: 2%;">
          <div class="dossier" style="background-color: #009CCA; color: #FFFFFF;">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_created_clicked.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="licenceLink" class="routerlink">
                {{ $lang.view.EditLicence.project }}<br/>
                <span style="font-size: 16px;">{{ nb_created_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_editing.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editingLink" class="routerlink">
                {{ $lang.view.EditLicence.editing }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_editing_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_edited.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editedLink" class="routerlink">
                {{ $lang.view.EditLicence.edited }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_edited_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_validated.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="validatedLink" class="routerlink">
                {{ $lang.view.EditLicence.validated }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_validated_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

        </div>

        <!-- Created projects list -->
        <div v-if="this.projects_list.length == 0" class="projects" style="display: flex; align-items: center; justify-content: center; width: 66%;">
          <div style="width: 270px;"><strong>{{ $lang.view.EditLicence.no_project }}</strong></div>
          <Button style="margin-top: 20px; width: 270px;" :text='$lang.view.EditLicence.new_project' v-on:click.native="createProject()" />
        </div>
        <div v-else class="projects" style="width: 66%;">
          <div style="flex: 1; max-height: 350px; overflow-y: auto;">

            <div class="project" v-for="(project, index) in projects_list" :key="index" style="display: flex; align-items: center; padding-left: 10px;">
              <div style="float: left; flex-grow: 1;">
                <img style="margin-right: 10px;" src="../assets/images/common/Project_created.svg" width="34px" height="37px" />
                <span class="pointer" v-on:click="selectProject(project)">{{ project.name }}</span>
              </div>
              <div style="float: right; margin-right: 10px;">
                <img style="float: right; cursor:pointer" @click="showDeleteWarning(project)" src="../assets/images/common/Corbeille.svg" alt="Corbeille"  width="23px" height="27px"/>
              </div>
            </div>

            <!-- Avertissement avant effacement -->
            <div v-if="show_delete_warning == 1" class="w3-modal" style="display: block;">
              <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
                <header class="w3-container">
                  <span @click="show_delete_warning = 0" class="w3-button w3-display-topright">&times;</span>
                  <h1 class="modal_header">{{$lang.view.EditLicence.warning}}</h1>
                </header>
                <div class="w3-container">
                  <br>
                  {{ $lang.view.EditLicence.delete_warning0 }} <strong>{{ project_name_to_delete }}</strong> ?
                  <br>
                  {{ $lang.view.EditLicence.delete_warning1 }}
                  <br><br>
                </div>
                <footer class="w3-container">
                  <button class="w3-button w3-margin-right" style="background-color:#eb8c40;"  @click="deleteProject">{{ $lang.view.EditLicence.yes }}</button>
                  <button class="w3-button"  @click="show_delete_warning = 0">{{ $lang.view.EditLicence.no }}</button>
                  <br><br>
                </footer>
              </div>
            </div>

          </div>
          <div style="margin-top: 10px; height: 70px;">
            <Button style="float: right; margin-right: 20px; width: 270px;" :text='$lang.view.EditLicence.new_project' v-on:click.native="createProject()" />
          </div>
        </div>

        <!-- Create project popup -->
        <div v-if="show_create_project == 1" class="w3-modal" style="display: block;">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
            <header class="w3-container" style="margin-bottom: 40px;">
              <span @click="show_create_project = 0" class="w3-button w3-display-topright">&times;</span>
              <h1 class="modal_header">{{$lang.view.EditLicence.new_project_title}}</h1>
            </header>
            <div class="w3-container" style="height: 300px; text-align: left;">
              <ValidationObserver ref="project_data_observer" v-slot="{ invalid, dirty }">

                <div style="margin-left: auto; margin-right: auto; margin-bottom: 40px; width: 430px;">
                  <span>{{$lang.view.EditLicence.projectname}}</span><br>
                  <ValidationProvider  :rules="{required: true, regex: regex, }" v-slot="{ errors }" v-bind:name="$lang.view.EditLicence.projectname">
                    <input class="project_name" type="text" v-model="project_name" :placeholder="$lang.view.EditLicence.placeholder"/>
                    <div class="field_error_message">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <!-- New project button -->
                <div style="width: 430px; margin: auto;">
                  <Button v-if="!invalid && dirty" @click.native="createNewProject()" :text='$lang.view.EditLicence.create_project' class='wide' />
                  <Button v-else :text='$lang.view.EditLicence.create_project' class='inactive' />
                  <LoadingAnimation v-if="loading" />
                </div>

              </ValidationObserver>
            </div>

            <!-- error message -->
            <div v-if="error_message" class="w3-panel w3-red">
              {{error_message}}
            </div>

            <!-- success message -->
            <div v-if="succes_message" class="w3-panel w3-green">
              {{succes_message}}
            </div>
          </div>
        </div>
      </div>

      <!-- users list -->
      <div class="users" v-if="userType == 'AGENCY' && this.users_list.length > 0" >
        <table class="tableau span-pointer img-pointer">
          <tr>
            <th style="width:35%;"> {{ $lang.view.EditLicence.login_header }} </th>
            <th style="width:35%;"> {{ $lang.view.EditLicence.email_header }} </th>
            <th style="width:30%;"> {{ $lang.view.EditLicence.rights_header }} </th>
          </tr>

          <tr v-for="(user, index) in users_list" :key="index">
            <td style="width:35%;"> {{ user.login }} </td>
            <td style="width:35%;"> {{ user.email }} </td>
            <td style="width:30%;"> <div style="padding-top: 16px;"><input type="checkbox" v-on:click="updateUserRights(user)" :id="index" v-model="user.validation_rights"/><label :for="index"/></div> </td>
          </tr>
        </table>
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import Button from '@/components/Button.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import PlusSignImage from '@/assets/images/common/Plus.svg'
// import PlusSignClickedImage from '@/assets/images/common/Plus-survol.svg'

export default {
  name: 'EditLicence',
  data () {
    return {
      // plus_sign_img: PlusSignImage,
      // plus_sign_clicked_img: PlusSignClickedImage,

      foldersLink: null,
      licenceLink: null,
      editingLink: null,
      editedLink: null,
      validatedLink: null,

      users_list: [],
      projects_list: [],

      show_delete_warning: 0,
      project_id_to_delete: null,
      project_name_to_delete: null,

      show_create_project: 0,
      loading: false,
      project_name: null,
      regex: /^[A-zÀ-ú\d-_ ]*$/,

      error_message: null,
      succes_message: null,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide,
    Button,
    LoadingAnimation,
    ValidationProvider,
    ValidationObserver
  },
  mounted () {
    this.reloadCurrentLicence()
  },
  created: function () {
    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`

    this.editingLink = this.licenceLink + '/editing'
    this.editedLink = this.licenceLink + '/edited'
    this.validatedLink = this.licenceLink + '/validated'
    if (this.$store.getters.userType === 'AGENCY') {
      this.getUserList()
    }
    this.getProjectList()
  },
  methods: {
    showDeleteWarning (project) {
      this.project_id_to_delete = project.id
      this.project_name_to_delete = project.name
      this.show_delete_warning = 1
    },
    deleteProject () {
      this.show_delete_warning = 0

      this.$http({
        method: 'DELETE',
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.project_id_to_delete
      })
        .then((resp) => {
          if (resp.status === 204) {
            // Update the created_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.created_project_counter--

            this.getProjectList()
          } else {
            this.error_message = resp.response.data.message
          }
        })
        .catch((error) => {
          if (error) this.error_message = error.response.data.message
        })
    },
    getUserList () {
      var request = {
        licence_id: this.$store.getters.currentLicence.id,
        limit: 500,
        offset: 0,
        sort: [['id', 'ASC']]
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/users/list',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.users_list = resp.data
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    getProjectList () {
      var request = {
        licence_id: this.$store.getters.currentLicence.id,
        state: 'created',
        limit: 500,
        sort: [['name', 'ASC']]
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/list',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.projects_list = resp.data
            resp.data.forEach(function (part, index, projects) {
              let sub = String(projects[index].brief_text).substring(0, 50)
              if (sub === 'null') sub = ''
              projects[index].brief_text_substring = sub + ' ...'
            })
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    updateUserRights (user) {
      user.validation_rights = !user.validation_rights

      this.$http({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: `/users/${user.id}`,
        data: {
          validation_rights: user.validation_rights
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    async selectProject (project) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      var projectName = project.name

      await this.$store.dispatch('setCurrentProject', project)
      this.$router.push(`/login/${agencyId}/${licenceId}/projects/${projectName}`)
    },

    createProject () {
      this.show_create_project = 1
    },

    reloadCurrentLicence () {
      var licenceId = this.$store.getters.currentLicence.id

      this.$http({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: `/licences/${licenceId}`,
        data: {}
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            var licence = resp.data
            await this.$store.dispatch('setCurrentLicence', licence)
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    setLoading (value) {
      this.loading = value
    },
    createNewProject () {
      this.loading = true
      var request = {
        name: this.project_name,
        licence_id: this.$route.params.licenceId,
        application_type: 'MaSolutionVideo'
      }
      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects',
        data: request
      })
        .then(async (resp) => {
          if (resp.status === 201) {
            // The new project is now the current project in the store
            // await this.$store.dispatch('setCurrentProject', resp.data)
            // TODO CL

            // Update the created_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.created_project_counter++

            // Go to the EditProject view
            // var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
            // var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
            // var projectName = resp.data.name
            // this.$router.push(`/login/${agencyId}/${licenceId}/projects/${projectName}`)

            this.getProjectList()

            // var bouton = document.querySelector('#button')
            // bouton.style.display = 'initial'
            this.setLoading(false)
            this.show_create_project = 0
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
          this.setLoading(false)
        })
    }
  },
  computed: {
    nb_created_projects () {
      return this.$store.getters.currentLicence.created_project_counter
    },
    nb_editing_projects () {
      return this.$store.getters.currentLicence.editing_project_counter
    },
    nb_edited_projects () {
      return this.$store.getters.currentLicence.edited_project_counter
    },
    nb_validated_projects () {
      return this.$store.getters.currentLicence.validated_project_counter
    },
    nb_credits () {
      return this.$store.getters.currentLicence.credits_counter
    },
    userType () {
      return this.$store.getters.userType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

.dossier {
  display: flex;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  height: 90px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 25px;
  text-align: left;
}

.projects {
  display: flex;
  flex-direction:column;
  margin-left: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  height: 420px;
  width: 100%;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 27px;
  text-align: center;
}

.project {
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 4px;
  width: 100%;
  height: 70px;

  color: #0C4168;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

.users {
  margin-top: 30px;
  padding-top: 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
}

.modal {
  background-color: #093555;
  border-radius: 20px;
  width: 600px;
  height: 400px;

  color: #FAF5F5;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  text-align: left;
}

.modal_header {
  color: #FAF5F5;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.16px;
  line-height: 38px;
  text-align: center;
}

.project_name {
  border-radius: 6px;
  width: 420px;
  height: 50px;

  color: #093555;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

.wide {
  cursor: pointer;
}

.inactive {
  cursor: default;
}

table.tableau {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

table.tableau th {
  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: center;
}

table.tableau td {
  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 13px;
}

input[type=checkbox] {
  display:none;
}

input[type=checkbox] + label {
  border: 1px solid #0C4168;
  border-radius: 3px;
  width: 17px;
  height: 17px;
  display:inline-block;
  background-size: 100%;
  background-color: #FFFFFF;
}

input[type=checkbox]:checked + label {
  background-color: #009CCA;
}

</style>
