<template>
  <div id="container" @drop="dropFileHandler" @dragenter.prevent @dragover.prevent>

    <!-- Navigation menu -->
    <div id="nav" ref="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main" ref="main">
      <div class="top-menu">
        <span><strong>{{ $route.params.projectName }}</strong></span>
        <hr>
      </div>

      <div id="fil_ariane">
        <span>
          <router-link v-bind:to="foldersLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_folders }}</router-link>
          <router-link v-bind:to="licenceLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_projects }}</router-link>
          <strong>{{ $route.params.projectName }}</strong>
        </span>
      </div>

      <div id="main_area" style="z-index: 1;">

        <!-- Edition of the brief and the files of the project  -->
        <div style="display: flex; flex-direction: row; height: 100%;">
          <div style="float: left; display: flex; flex-direction: column; padding: 10px; height: 100%; width: 50%;">
            <span>
              <img v-on:click="editBrief()" src="@/assets/images/common/Crayon-de-modification-couleur.svg" style="margin-right: 10px; height: 20px; width: 37px; cursor: pointer;" />
              <strong>{{ $lang.view.EditProject.brief }}</strong>
            </span>
            <div style="margin-bottom: 10px; width: 100%; height: 50%;">
              <div style="margin-top: 0px; margin-bottom: 0px; height: 100%;" @drop="dropFileHandler" @dragenter.prevent @dragover.prevent>
                <Snackbar v-model="show_brief_saved_snackbar" :text="$lang.view.EditProject.brief_saved"></Snackbar>
                <textarea v-on:input="flagBriefAsDirty" ref="edit_brief_textarea" :placeholder="$lang.view.EditProject.brief_brief" v-model="brief_text"></textarea>
              </div>
            </div>
            <Button ref="saveBrief" style="margin-bottom:1em;" v-on:click.native="updateBrief()" :text='$lang.view.EditProject.save_brief' />
            <span>
              <img src="@/assets/images/common/Ajout-couleur.svg" style="margin-right: 10px; height: 31px; width: 29px; cursor: pointer;" />
              <strong>{{ $lang.view.EditProject.add_elements }}</strong>
            </span>

            <!-- Drag&Drop files -->
            <div style="margin-top: 10px; height: 50%;">
              <div id="file_drop_box_container">
                <div id="file_drop_box">
                  <UploadRushFiles  @emptyFile="emptyFileError" @uploadStart="uploadStart" @uploadEnd="uploadEnd" id="UploadRushFiles" v-on:file-uploaded="getProjectContents" />
                </div>
              </div>
            </div>
          </div>

          <div style="float: right; display: flex; flex-direction: column; padding: 10px; height: 100%; width: 50%;">
            <span>
              <img src="@/assets/images/common/Dossier-vert.svg" style="margin-right: 10px; height: 24px; width: 29px; cursor: pointer;" />
              <strong>{{ $lang.view.EditProject.contents }} ({{ contents_count }})</strong>
            </span>
            <div style="float: right; display: flex; flex-direction: column; margin-bottom: 10px; padding: 10px; width: 100%; height: 90%;">
              <div style="flex: 1; max-height: 100%; overflow-y: auto;" @drop="dropFileHandler" @dragenter.prevent @dragover.prevent>
                <div>
                    <div class="rush" v-for="(rush, index) in rushes_list" :key="index" style="display: flex; align-items: center; padding-left: 5px;">
                      <div style="float: left; flex-grow: 1; display: flex; align-items: center; max-width: 85%; height: 32px; overflow-x: auto; overflow-y: hidden;">
                        <span class="pointer" :title="rush.name" v-on:click="downloadRushFile(rush.name, rush.rush_file_url, rush.file_url)">{{ rush.filename_substring }}</span>
                      </div>
                      <div style="float: right; margin-left: 5px; margin-right: 5px;">
                        <img v-on:click="show_before_edit_rush_name_message = 1; selectRush(rush)" :src="edit_rush_img" class="hoverDarken" style="height: 16px; width:16px; cursor: pointer; margin-right: 8px;" />
                        <img v-on:click="show_before_delete_rush_message = 1; selectRush(rush)" :src="delete_rush_img" style="height: 18px; width:15px; cursor: pointer; margin-right: 8px;" />
                      </div>
                    </div>
                </div>
                <Snackbar :error='true' v-model="show_empty_file_error_snackbar" :text="$lang.view.EditProject.empty_file"></Snackbar>

                <!-- Ask for confirmation before editing rush name-->
                <ValidationObserver ref="editForm" v-slot="{ invalid }" >
                <form @submit.prevent="editRushName(selectedRush, rushBaseName + rushExtension)">
                  <div v-if="show_before_edit_rush_name_message == 1" class="w3-modal" style="display: block;">
                    <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
                      <header class="w3-container">
                        <span @click="show_before_edit_rush_name_message = 0" class="w3-button w3-display-topright">&times;</span>
                        <h1 class="modal_header">{{$lang.view.EditProject.before_edit_rush_name_information}}</h1>
                      </header>
                      <div class="w3-container">
                        <br>
                        {{ $lang.view.EditProject.before_edit_rush_name_message }}
                        <br>
                        <ValidationProvider :name="$store.getters.lang == 'fr' ? 'nom' : 'name'" rules="required" v-slot="{ errors }">
                          <input ref="rushName" style="width:80%" v-model="rushBaseName" /><span><b>{{ rushExtension }}</b></span>
                          <p class="field_error_message">{{errors[0]}}</p>
                        </ValidationProvider>
                      </div>
                      <footer class="w3-container w3-center">
                        <button id='renameRushFileSubmitButton' style="background-color:#eb8c40;"  :disabled="invalid" type=submit class="w3-button w3-margin-right">{{ $lang.view.EditProject.ok }}</button>
                        <button type="button" class="w3-button"  @click="show_before_edit_rush_name_message = 0">{{ $lang.view.EditProject.cancel }}</button>
                        <br><br>
                      </footer>
                    </div>
                  </div>
                </form>
                </ValidationObserver>

                <!-- Ask for confirmation before deleting rush -->
                <div v-if="show_before_delete_rush_message == 1" class="w3-modal" style="display: block;">
                  <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
                    <header class="w3-container">
                      <span @click="show_before_delete_rush_message = 0" class="w3-button w3-display-topright">&times;</span>
                      <h1 class="modal_header">{{$lang.view.EditProject.before_delete_rush_information}}</h1>
                    </header>
                    <div class="w3-container">
                      <br>
                      {{ $lang.view.EditProject.before_delete_rush_message }} : <b>{{ this.selectedRush.file_url }}</b> ?
                      <br><br>
                    </div>
                    <footer class="w3-container w3-center">
                      <button class="w3-button w3-margin-right" style="background-color:#eb8c40;"  @click="deleteRush(selectedRush)">{{ $lang.view.EditProject.ok }}</button>
                      <button class="w3-button"  @click="show_before_delete_rush_message = 0">{{ $lang.view.EditProject.cancel }}</button>
                      <br><br>
                    </footer>
                  </div>
                </div>

              </div>
            </div>
            <!-- Send project -->
            <div style="float: right;">
              <Button v-if="uploading" text='Uploading ...' :color="uploadColor" style="cursor:normal" />
              <Button v-else v-on:click.native="show_before_sending_message = 1" :text='$lang.view.EditProject.send_project' :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
            </div>
          </div>
        </div>
      </div>

      <LoadingAnimation v-if="loading" :progressEvent="progressEvent" />

      <!-- error message -->
      <div v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>

      <div style="z-index: 20;">
        <!-- Confirmation message after sending project -->
        <div v-if="show_sent_message == 1" class="w3-modal" style="display: block;">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
            <header class="w3-container">
              <span @click="goToEditLicence" class="w3-button w3-display-topright">&times;</span>
              <h1 class="modal_header">{{$lang.view.EditProject.information}}</h1>
            </header>
            <div class="w3-container">
              <br>
              {{ $lang.view.EditProject.sent_confirmed }}
              <br><br>
            </div>
            <footer class="w3-container w3-center">
              <button class="w3-button" @click="goToEditLicence">{{ $lang.view.EditProject.ok }}</button>
              <br><br>
            </footer>
          </div>
        </div>

        <!-- Ask for confirmation before sending project -->
        <div v-if="show_before_sending_message == 1" class="w3-modal" style="display: block;">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
            <header class="w3-container">
              <span @click="show_before_sending_message = 0" class="w3-button w3-display-topright">&times;</span>
              <h1 class="modal_header">{{$lang.view.EditProject.information}}</h1>
            </header>
            <div class="w3-container">
              <br>
              {{ $lang.view.EditProject.before_sending }}
              <br><br>
            </div>
            <footer class="w3-container w3-center">
              <button class="w3-button w3-margin-right" style="background-color:#eb8c40;"  @click="sendProject()">{{ $lang.view.EditProject.ok }}</button>
              <button class="w3-button"  @click="show_before_sending_message = 0">{{ $lang.view.EditProject.cancel }}</button>
              <br><br>
            </footer>
          </div>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side" ref="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import editRushImage from '../assets/images/common/Crayon-de-modification.svg'
import deleteRushImage from '../assets/images/common/Corbeille.svg'
import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'

import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import Button from '@/components/Button.vue'
import UploadRushFiles from '@/components/UploadRushFiles.vue'
import Snackbar from '@/components/Snackbar.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
  name: 'EditProject',
  data () {
    return {
      selectedRush: null,
      rushBaseName: null,
      edit_rush_img: editRushImage,
      show_before_edit_rush_name_message: 0,
      delete_rush_img: deleteRushImage,
      show_before_delete_rush_message: 0,
      show_sent_message: 0,
      show_before_sending_message: 0,

      brief_text: null,
      brief_dirty: 0,
      rushes_list: null,
      contents_count: 0,

      foldersLink: null,
      licenceLink: null,
      editingLink: null,
      editedLink: null,
      validatedLink: null,

      show_brief_saved_snackbar: false,
      show_empty_file_error_snackbar: false,

      loading: false,
      progressEvent: null,
      uploading: 0,
      uploadColor: '#59C',

      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage,

      error_message: null,
      debug: 1
    }
  },
  props: {},
  components: {
    NavigationMenu,
    RightSide,
    UploadRushFiles,
    Button,
    Snackbar,
    LoadingAnimation,
    ValidationProvider,
    ValidationObserver
  },
  mounted () {
    this.getProjectContents()
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('beforeunload', this.beforeWindowUnload)

    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`

    this.editingLink = this.licenceLink + '/editing'
    this.editedLink = this.licenceLink + '/edited'
    this.validatedLink = this.licenceLink + '/validated'

    this.brief_text = this.$store.getters.currentProject.brief_text
    this.brief_version = this.$store.getters.currentProject.brief_version
  },
  beforeRouteLeave (to, from, next) {
    if (this.brief_dirty) {
      const answer = window.confirm(this.$lang.view.EditProject.brief_dirty)
      this.highlightSaveButton()
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  methods: {
    handleScroll: function () {
      this.$refs.nav.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.main.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.right_side.style.height = this.$refs.main.scrollHeight + 'px'
    },
    handleResize: function () {
      let h = this.$refs.main.scrollHeight
      if (window.innerHeight > h) { h = window.innerHeight }
      this.$refs.nav.style.height = h + 'px'
      this.$refs.main.style.height = h + 'px'
      this.$refs.right_side.style.height = h + 'px'
    },

    beforeWindowUnload (e) {
      if (this.brief_dirty) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
        this.highlightSaveButton()
      }
    },

    highlightSaveButton () {
      let button = this.$refs.saveBrief.$el
      button.classList.add('wiggle')
    },

    emptyFileError () {
      this.show_empty_file_error_snackbar = true
    },

    uploadStart () {
      this.uploading++
    },

    uploadEnd () {
      this.uploading--
    },

    editBrief () {
      this.$refs.edit_brief_textarea.focus()
    },

    flagBriefAsDirty () {
      this.brief_dirty = 1
    },

    getProjectContents () {
      var request = {}

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/rusheslist',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            resp.data.forEach(function (part, index, rushes) {
              rushes[index].filename_substring = rushes[index].file_url
            })
            this.rushes_list = resp.data
            if (resp.data) {
              this.contents_count = resp.data.length
            }
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    isDragSourceExternalFile (dataTransfer) {
      // Source detection for Safari v5.1.7 on Windows.
      var DragDataType = dataTransfer.types
      if (typeof Clipboard !== 'undefined') {
        if (dataTransfer.constructor === 'Clipboard') {
          if (dataTransfer.files.length > 0) {
            return true
          } else {
            return false
          }
        }
      }

      // Source detection for Firefox on Windows.
      if (typeof DOMStringList !== 'undefined') {
        if (DragDataType.constructor === DOMStringList) {
          if (DragDataType.contains('Files')) {
            return true
          } else {
            return false
          }
        }
      }

      // Source detection for Chrome on Windows.
      if (typeof Array !== 'undefined') {
        // var DragDataType = dataTransfer.types
        if (DragDataType.constructor === Array) {
          if (DragDataType.indexOf('Files') !== -1) {
            return true
          } else {
            return false
          }
        }
      }
    },

    dropFileHandler (e) {
      var IsFile = this.isDragSourceExternalFile(e.dataTransfer)
      if (IsFile) {
        e.preventDefault()
        return false
      }
    },

    downloadRushFile (rushname, rushFileUrl, fileUrl) {
      var request = {
        file_url: rushFileUrl
      }

      this.loading = true

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        responseType: 'blob', // arraybuffer
        url: '/projects/' + this.$store.getters.currentProject.id + '/file',
        data: request,
        onDownloadProgress: (progressEvent) => {
          this.progressEvent = progressEvent
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.downloadFile(resp.data, rushname)
          }
        })
        .catch((error) => {
          this.loading = false
          if (error) {
            this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
          }
        })
        .finally(() => {
          this.progressEvent = null
        })
    },
    eventFire (el, etype) {
      if (el.fireEvent) {
        el.fireEvent('on' + etype)
      } else {
        var evObj = document.createEvent('Events')
        evObj.initEvent(etype, true, false)
        el.dispatchEvent(evObj)
      }
    },
    downloadFile (blob, filename) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        let fileURL = window.URL.createObjectURL(blob)
        let fileLink = document.createElement('a')
        fileLink.setAttribute('target', '_blank')
        fileLink.setAttribute('href', fileURL)
        fileLink.setAttribute('download', filename)
        fileLink.style.visibility = 'hidden'
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
        window.URL.revokeObjectURL(fileURL)
        fileLink.remove()
      }
      this.loading = false
    },
    async updateBrief () {
      return new Promise((resolve, reject) => {
        var request = {
          'brief_text': this.brief_text,
          'brief_version': this.brief_version
        }

        this.$http({
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: '/projects/' + this.$store.getters.currentProject.id + '/brief',
          data: request
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.brief_version++

              this.show_brief_saved_snackbar = true
              this.brief_dirty = 0
              resolve('OK')
            }
          })
          .catch((error) => {
            if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
            reject(error)
          })
      })
    },
    selectRush (rush) {
      this.rushBaseName = rush.file_url.slice(0, (rush.file_url.lastIndexOf('.') - 1 >>> 0) + 1)
      this.rushExtension = rush.name.slice((rush.name.lastIndexOf('.') - 1 >>> 0) + 1)
      this.selectedRush = rush
    },
    editRushName (rush, newRushName) {
      var request = {
        'file_name': rush.file_url,
        'new_file_name': newRushName
      }

      this.$http({
        method: 'PUT',
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/renamerushfile/',
        data: request
      })
        .then((resp) => {
          this.show_before_edit_rush_name_message = 0
          this.getProjectContents()
        })
        .catch((error) => {
          if (error) this.error_message = error.response.data.message
        })
    },
    deleteRush (rush) {
      this.show_delete_warning = 0

      this.$http({
        method: 'DELETE',
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/deleterushfile',
        data: {
          'file_name': rush.file_url
        }
      })
        .then((resp) => {
          this.show_before_delete_rush_message = 0
          this.getProjectContents()
        })
        .catch((error) => {
          if (error) this.error_message = error.response.data.message
        })
    },
    async sendProject () {
      let retur = await this.updateBrief()
      console.log(retur)
      var request = {
        application_type: 'MaSolutionVideo'
      }

      this.$http({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/newstate_editing',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            // Update the created_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.created_project_counter--
            // Update the editing_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.editing_project_counter++

            this.show_before_sending_message = 0
            this.show_sent_message = 1
          }
        })
        .catch((error) => {
          this.show_before_sending_message = 0
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    goToEditLicence () {
      this.show_sent_message = 0

      // Go to the EditLicence view
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      this.$router.push(`/login/${agencyId}/${licenceId}`)
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/global";
.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

#fil_ariane {
  margin-bottom: 20px;

  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 19px;
  text-align: left;
}

#main_area {
  background-color: #FFFFFF;
  border-radius: 10px;

  width: 100%;
  height: 75%;

  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 19px;
  text-align: left;
}

.rush {
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  width: 100%;
  height: 52px;

  color: #093555;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

textarea {
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  resize: none;
  width: 100%;
  height: 100%;

  color: #75767a;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.08px;
  text-align: left;
}

.wiggle {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

#file_drop_box_container {
    margin-top: 0px;
    padding: 2px;
    width: 100%;
    height: 100%;
}

#file_drop_box {
    border-color: #0C4168;
    border-style: dashed;
    background-color: rgb(252,236,198);
    background-size: contain;
    cursor: pointer;

    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.modal {
  background-color: #093555;
  border-radius: 20px;
  width: 600px;
  height: 400px;

  color: #FAF5F5;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  text-align: left;
}

.modal_header {
  color: #FAF5F5;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.16px;
  line-height: 38px;
  text-align: center;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>
