<template>
  <div id="hover_button">
    <img @click="buttonClicked" :src="image" alt="img" :width="img_width" :height="img_height" :title="img_title" @mouseover="hover_handler" @mouseleave="hover_leave_handler"/>
  </div>
</template>

<script>
export default {
  name: 'HoverButton',
  data: function () {
    return {
      image: null
    }
  },
  props: {
    img: String,
    clicked_img: String,
    img_width: String,
    img_height: String,
    img_title: String
  },
  created: function () {
    this.image = this.img
  },
  methods: {
    buttonClicked: function () {
      this.$emit('on-clicked-button')
    },
    hover_handler () {
      this.image = this.clicked_img
    },
    hover_leave_handler () {
      this.image = this.img
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/global";
</style>
