<template>
  <div id="cgu">

    <div style="margin:auto; width: 410px;" class="w3-container">

      <!-- CGU -->
      <div class="w3-modal" style="display: block;">
        <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top" style="width: 90%;">

          <header class="w3-container modal_header">
            <span @click="logout()" class="w3-button w3-display-topright">&times;</span>
            <h3>CGU</h3>
          </header>

          <CguAgency v-if="this.$store.getters.currentUser.type === 'AGENCY'"/>
          <CguClient v-else />

          <br><br>
          <footer class="w3-container w3-center">
              <Button class="w3-left" v-on:click.native="logout()" :text='$lang.view.Cgu.refuse' />
              <Button class="w3-right" v-on:click.native="accept()" :text='$lang.view.Cgu.accept' />
              <br><br>
              <br><br>
          </footer>

        </div>
      </div>

      <br>
      <br>

      <!-- error message -->
      <div v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Button from '@/components/Button.vue'
import CguAgency from '@/components/CguAgency.vue'
import CguClient from '@/components/CguClient.vue'

export default {
  data () {
    return {
      error_message: ''
    }
  },
  props: {
  },
  components: {
    Button,
    CguAgency,
    CguClient
  },
  created () {
  },
  mounted () {
    if (this.$store.getters.userCguAccepted && !this.ok) {
      this.nextPage()
    }
  },
  methods: {
    async nextPage () {
      var agencyId
      if (this.$store.getters.currentUser.type === 'LICENCE_USER' || this.$store.getters.currentUser.type === 'LICENCE_OWNER') {
        agencyId = this.$store.getters.currentAgency.id + '-' + this.$store.getters.currentAgency.login
        var licenceId = this.$store.getters.currentLicence.id + '-' + this.$store.getters.currentLicence.name
        this.$router.push(`/login/${agencyId}/${licenceId}`)
      } else {
        await this.$store.dispatch('setCurrentAgency', this.$store.getters.currentUser)
        agencyId = this.$store.getters.currentAgency.id + '-' + this.$store.getters.currentAgency.login
        this.$router.push(`/login/${agencyId}/folders`)
      }
    },

    async accept () {
      // Accept CGU
      try {
        var resp = await this.$http({
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: '/users/' + this.$store.getters.currentUser.id,
          data: {
            cgu_accepted: true
          }
        })

        if (resp.status === 200) {
          this.nextPage()
        } else {
          this.error_message = 'Error : cannot accept CGU'
        }
      } catch (error) {
        if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
      }
    },

    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

#cgu {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 30px;
  color: black;
}

#text1 {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  color: #AAC6B9;
}

</style>
