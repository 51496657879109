<template>
  <div id="container" @click="hideErrorPanel">

    <!-- Navigation menu -->
    <div id="nav" ref="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main" ref="main">
      <div class="top-menu">
        <span><strong>{{ $route.params.projectName }}</strong></span>
        <hr>
      </div>

      <div id="fil_ariane">
        <span>
          <router-link v-bind:to="foldersLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_folders }}</router-link>
          <router-link v-bind:to="licenceLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_projects }}</router-link>
          <router-link v-bind:to="editedLink" class="routerlink" style="margin-right: 20px;">{{ $lang.view.EditProject.my_edited }}</router-link>
          <strong>{{ $route.params.projectName }}</strong>
        </span>
      </div>

      <!-- Video player -->
      <div>
        <img src="@/assets/images/common/share.svg" style="margin-right: 10px; height: 30px; width:30px;" />
        <a class="pointer" v-on:click="createPublicLink()">{{ $lang.view.ValidateVideo.public_link }}</a>
      </div>
      <Snackbar v-model="show_public_link_copied_snackbar" :text="$lang.view.ValidateVideo.public_link_copied"></Snackbar>

      <div style="margin-top: 10px; margin-bottom: 30px; width: 90%;">
        <video id="videoPlayer" class="video-js" width="700px" height="400px" controls preload="auto" data-setup="{}">
          <p class="vjs-no-js">
            Enable javascript to view this video
          </p>
        </video>
      </div>

      <div v-if="userValidationRights" style="margin-bottom: 10px; width: 90%;">
        <div style="display: inline-flex; width: 100%;">
          <div id="validate_video_modify_project" v-on:click="modifyProject()" style="width: 50%;">
            <Button style="width: 90%; margin-left: auto; margin-right: auto;" :text='$lang.view.ValidateVideo.modify_project' />
          </div>
          <div style="width: 50%;">
              <Button style="width: 90%; margin-left: auto; margin-right: auto;" :text='$lang.view.ValidateVideo.validate' v-on:click.native="confirmValidateProject()" :icon="check_img" :clicked_icon="check_clicked_img" />
          </div>
        </div>
        <br/>
        <div class="text_area" v-if="show_brief_text_edit_zone">
          <div style="margin-bottom: 10px;">{{ $lang.view.ValidateVideo.your_request }}</div>
          <textarea autofocus ref="update_brief_textarea" v-model="brief_text" :placeholder="$lang.view.ValidateVideo.brief_placeholder"></textarea>
          <Button style="float: right; margin-top: 0px; width: 330px;" :text='$lang.view.ValidateVideo.send' v-on:click.native="updateBriefAndSendProject()" :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
          <div ref="text_anchor" style="width: 100%; height: 150px;"></div>
        </div>
      </div>

      <!-- Warning before validating project -->
      <div v-if="show_validate_warning == 1" class="w3-modal" style="display: block;">
        <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
          <header class="w3-container">
            <span @click="show_validate_warning = 0" class="w3-button w3-display-topright">&times;</span>
            <h1 class="modal_header">{{$lang.view.ValidateVideo.warning}}</h1>
          </header>
          <div class="w3-container">
            <br>
            {{ $lang.view.ValidateVideo.validate_warning }}
            <br><br>
          </div>
          <footer class="w3-container">
            <button class="w3-button w3-margin-right" style="background-color:#eb8c40;" @click="validateProject">{{ $lang.view.ValidateVideo.yes }}</button>
            <button class="w3-button" @click="show_validate_warning = 0">{{ $lang.view.ValidateVideo.no }}</button>
            <br><br>
          </footer>
        </div>
      </div>

      <!-- Success after validating project -->
      <div v-if="show_validate_success == 1" class="w3-modal" style="display: block;">
        <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
          <header class="w3-container">
            <span @click="redirectToValidatedProjects" class="w3-button w3-display-topright">&times;</span>
            <h1 class="modal_header">{{$lang.view.ValidateVideo.success}}</h1>
          </header>
          <div class="w3-container">
            <br>
            {{ $lang.view.ValidateVideo.validate_success }}
            <br><br>
          </div>
          <footer class="w3-container">
            <button class="w3-button w3-margin-right" @click="redirectToValidatedProjects">{{ $lang.view.ValidateVideo.ok }}</button>
            <br><br>
          </footer>
        </div>
      </div>

      <!-- Confirmation message after modifying project -->
      <div v-if="show_modify_message == 1" class="w3-modal" style="display: block;">
        <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top modal">
          <header class="w3-container">
            <span @click="goToEditLicence" class="w3-button w3-display-topright">&times;</span>
            <h1 class="modal_header">{{$lang.view.ValidateVideo.information}}</h1>
          </header>
          <div class="w3-container">
            <br>
            {{ $lang.view.ValidateVideo.modification_confirmed }}
            <br><br>
          </div>
          <footer class="w3-container w3-center">
            <button class="w3-button" @click="goToEditLicence">{{ $lang.view.ValidateVideo.ok }}</button>
            <br><br>
          </footer>
        </div>
      </div>

      <!-- error message -->
      <div id="page-mask" v-if="error_message"></div>
      <div ref="error_panel" id="error_panel" v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side" ref="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import 'video.js/dist/video-js.css'
import _videojs from 'video.js'
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import Button from '@/components/Button.vue'
import CheckImage from '@/assets/images/common/Check.svg'
import CheckClickedImage from '@/assets/images/common/Check-survol.svg'
import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'
import Snackbar from '@/components/Snackbar.vue'

const videojs = window.videojs || _videojs

export default {
  name: 'ValidateVideo',
  data () {
    return {
      project: null,
      player: null,
      brief_text: null,
      show_brief_text_edit_zone: false,
      show_validate_warning: 0,
      show_validate_success: 0,
      show_modify_message: 0,

      foldersLink: null,
      licenceLink: null,
      editedLink: null,
      validatedLink: null,

      check_img: CheckImage,
      check_clicked_img: CheckClickedImage,

      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage,

      error_message: null,
      show_public_link_copied_snackbar: false,
      debug: 1
    }
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    NavigationMenu,
    RightSide,
    Button,
    Snackbar
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.project = this.$store.getters.currentProject
  },
  destroyed: function () {
    document.querySelector('#videoPlayer').removeEventListener('contextmenu', function () {})
  },
  mounted () {
    this.player = videojs('videoPlayer')

    var path = this.project.download_url + '/' + this.project.edited_file_url
    var downloadUrl = path.replace(/[\\]+/g, '/')
    console.log('downloadUrl: ' + downloadUrl)

    this.player.src({ type: 'video/mp4', src: downloadUrl })
    this.player.on('error', (event) => {
      this.error_message = this.$lang.view.PlayVideo.no_video_link
    })

    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`
    this.editedLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}/edited`
    this.validatedLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}/validated`

    document.querySelector('#videoPlayer').addEventListener('contextmenu', function (e) {
      e.preventDefault()
    }, false)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    hideErrorPanel () {
      this.error_message = null
    },
    handleScroll: function () {
      this.$refs.nav.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.main.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.right_side.style.height = this.$refs.main.scrollHeight + 'px'
    },
    handleResize: function () {
      let h = this.$refs.main.scrollHeight
      if (window.innerHeight > h) { h = window.innerHeight }
      this.$refs.nav.style.height = h + 'px'
      this.$refs.main.style.height = h + 'px'
      this.$refs.right_side.style.height = h + 'px'
    },

    confirmValidateProject () {
      this.show_validate_warning = 1
    },

    validateProject () {
      var request = {
        application_type: 'MaSolutionVideo'
      }

      this.$http({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/newstate_validated',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            // Update the edited_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.edited_project_counter--
            // Update the validated_project_counter counter in the current licence in the store
            this.$store.getters.currentLicence.validated_project_counter++

            this.show_validate_warning = false
            this.show_validate_success = true
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    redirectToValidatedProjects () {
      return this.$router.push(this.validatedLink)
    },

    modifyProject () {
      this.show_brief_text_edit_zone = true

      this.$refs.nav.style.height = '1200px'
      this.$refs.main.style.height = '1200px'
      this.$refs.right_side.style.height = '1200px'

      window.scrollTo(0, this.$refs.text_anchor.offsetTop)
    },

    updateBriefAndSendProject () {
      var request = {
        brief_text: this.brief_text,
        brief_version: this.$store.getters.currentProject.brief_version,
        application_type: 'MaSolutionVideo'
      }

      this.$http({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/' + this.$store.getters.currentProject.id + '/brief',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            request = {
              application_type: 'MaSolutionVideo'
            }

            this.$http({
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              baseURL: this.$store.state.config.webapiurl,
              url: '/projects/' + this.$store.getters.currentProject.id + '/newstate_editing',
              data: request
            })
              .then((resp) => {
                if (resp.status === 200) {
                  // Update the edited_project_counter counter in the current licence in the store
                  this.$store.getters.currentLicence.edited_project_counter--
                  // Update the editing_project_counter counter in the current licence in the store
                  this.$store.getters.currentLicence.editing_project_counter++

                  this.show_modify_message = 1
                }
              })
              .catch((error) => {
                if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
              })
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    goToEditLicence () {
      this.show_modify_message = 0

      // Go to the EditLicence view
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      this.$router.push(`/login/${agencyId}/${licenceId}`)
    },

    createPublicLink () {
      this.show_public_link_copied_snackbar = true
      let link = this.$store.state.config.weburl + '/#/' +
        this.project.edited_file_url.replace(/[\\]+/g, '/') + '/' + 'watch'
      let el = document.createElement('textarea')
      el.value = link
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }

  },
  computed: {
    userValidationRights () {
      return this.$store.getters.userValidationRights
    }
  }
}
</script>

<style lang="scss">
.video-js .vjs-time-control {
    display: block!important;
}
.video-js .vjs-remaining-time {
    display: none!important;
}
</style>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

#fil_ariane {
  margin-bottom: 20px;

  color: #093555;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 19px;
  text-align: left;
}

.text_area {
  margin-top: 20px;
  width: 100%;

  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 24px;
  text-align: left;
}

textarea {
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  resize: none;
  width: 100%;
  height: 225px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 24px;
  text-align: left;

}

#validate_video_modify_project {
}

#validate_video_modify_project:hover {
  cursor: pointer!important;
}

.modal {
  background-color: #093555;
  border-radius: 20px;
  width: 600px;
  height: 400px;

  color: #FAF5F5;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  text-align: left;
}

.modal_header {
  color: #FAF5F5;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.16px;
  line-height: 38px;
  text-align: center;
}

.pointer {
  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 22px;
  text-align: center;
  text-decoration: underline;
}

#page-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#error_panel {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 600px;
  margin-left: -300px;

  height: 140px;
  margin-top: -70px;
}

</style>
