<template>
  <div  v-bind:class="{button: !(!this.icon || this.clicked_icon), activebutton: (!this.icon || this.clicked_icon)}" :style="{backgroundColor: color }" @click="go" @mouseover="hover_handler" @mouseleave="hover_leave_handler">
    <div style="float: left; padding-right: 15px; flex-grow: 1; text-align: center;">{{ text }}</div>
    <div style="float: right;" v-if="this.image"><img :src="this.image" class="icon" /></div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  data: function () {
    return {
      image: null
    }
  },
  props: {
    text: String,
    width: String,
    height: String,
    to: String,
    color: String,
    icon: String,
    clicked_icon: String
  },
  created: function () {
  },
  mounted () {
    this.image = this.icon
  },
  methods: {
    go () {
      if (this.to) {
        this.$router.push(this.to)
      }
    },
    hover_handler () {
      if (this.clicked_icon) this.image = this.clicked_icon
    },
    hover_leave_handler () {
      if (this.icon) this.image = this.icon
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;

  border: 1px solid #009CCA;
  color: white;
  background-color: #009CCA;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 5px;
  cursor: pointer;

  font-family: Poppins;
  font-size: 16px;
  font-weight: light;
  letter-spacing: 0.09px;
  line-height: 40px;
}

.activebutton {
    @extend .button;
}

.activebutton:hover {
  border: 1px solid #0C4168;
  color: #009CCA;
  background-color: white;
}

.icon {
  width: 40px;
  height: 40px;
}

</style>
