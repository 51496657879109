import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Login from '../views/Login.vue'
import Cgu from '../views/Cgu.vue'

import InitPassword from '../views/InitPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import LostPassword from '../views/LostPassword.vue'
import CheckPassword from '../views/CheckPassword.vue'

import LostId from '../views/LostId.vue'
import CheckId from '../views/CheckId.vue'

import Tutos from '../views/Tutos.vue'

import ListLicences from '../views/ListLicences.vue'
import CreateLicence from '../views/CreateLicence.vue'
import EditLicence from '../views/EditLicence.vue'

import ListEditingProjects from '../views/ListEditingProjects.vue'
import ListEditedProjects from '../views/ListEditedProjects.vue'
import ListValidatedProjects from '../views/ListValidatedProjects.vue'

import EditProject from '../views/EditProject.vue'
import EditingProject from '../views/EditingProject.vue'
import ValidateVideo from '../views/ValidateVideo.vue'
import PlayVideo from '../views/PlayVideo.vue'
import PublicViewer from '../views/PublicViewer.vue'

import EditMyInfo from '../views/EditMyInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/cgu',
    name: 'Cgu',
    component: Cgu
  },
  {
    path: '/initpassword',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/initpassword/:token',
    name: 'initpassword',
    component: InitPassword
  },
  {
    path: '/resetpassword',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/resetpassword/:token',
    name: 'resetpassword',
    component: ResetPassword
  },
  {
    path: '/lostpassword',
    name: 'LostPassword',
    component: LostPassword
  },
  {
    path: '/lostpassword/checkpassword',
    name: 'CheckPassword',
    component: CheckPassword
  },
  {
    path: '/lostid',
    name: 'LostId',
    component: LostId
  },
  {
    path: '/lostid/checkid',
    name: 'CheckId',
    component: CheckId
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/tutos',
    name: 'Tutos',
    component: Tutos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myinfos',
    name: 'EditMyInfo',
    component: EditMyInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/folders',
    name: 'ListLicences',
    component: ListLicences,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/createlicence',
    name: 'CreateLicence',
    component: CreateLicence,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName',
    name: 'EditLicence',
    component: EditLicence,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/projects/:projectName',
    name: 'EditProject',
    component: EditProject,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/editing/:projectName',
    name: 'EditingProject',
    component: EditingProject,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/editing',
    name: 'ListEditingProjects',
    component: ListEditingProjects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/edited',
    name: 'ListEditedProjects',
    component: ListEditedProjects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/:projectID-:projectName/validatevideo',
    name: 'ValidateVideo',
    component: ValidateVideo,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/validated',
    name: 'ListValidatedProjects',
    component: ListValidatedProjects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/:agencyId-:agencyName/:licenceId-:licenceName/:projectID-:projectName/playvideo',
    name: 'PlayVideo',
    component: PlayVideo,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:agencyId-:agencyName/:licenceId-:licenceName/edited/:projectID-:projectName/watch',
    name: 'PublicViewer',
    component: PublicViewer
  }
]

const router = new VueRouter({
  base: store.state.config.publicPath,
  routes
})

router.beforeEach((to, from, next) => { // User access checking
  if (to.matched.some(record => record.meta.requiresAuth)) { // For views requiring authentification and token
    if (store.getters.isLoggedIn) { // If there's a token in the store
      var promise = store.dispatch('loginToken', { login: store.getters.authToken }) // Check token validity
      promise
        .then(() => next()) // If valid allow
        .catch(() => next('/login')) // If invalid go to login
    } else { // If there is no token in the store
      next('/login')
    }
  } else { // Free views
    next()
  }
})

export default router
