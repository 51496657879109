<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- Main -->
    <div id="main">

      <div class="top-menu">
        <span><strong>{{ $lang.view.CreateLicence.title }}</strong></span>
        <hr/>
      </div>

      <div>
        <ValidationObserver ref="folder_data_observer" v-slot="{ invalid, dirty }">
            <div style="padding: 0px;" class="w3-container">
              <!-- licence name -->
              <span class="sub-menu">{{ $lang.view.CreateLicence.name }}</span>
              <ValidationProvider  :rules="{required: true, regex: regex, }" v-slot="{ errors }" v-bind:name="$lang.view.CreateLicence.name">
                <input class="input-field w3-input" type="text" v-model="name" >
                <div class="field_error_message">{{ errors[0] }}</div>
              </ValidationProvider>
              <br>

              <!-- email of the user of the licence -->
              <span class="sub-menu">{{ $lang.view.CreateLicence.email }}</span>
              <ValidationProvider  rules="required|email" v-slot="{ errors }" v-bind:name="$lang.view.CreateLicence.email">
                <input class="input-field w3-input" type="text" v-model="email" >
                <div class="field_error_message">{{ errors[0] }}</div>
              </ValidationProvider>
              <br>

              <!-- phone of the user of the licence -->
              <span class="sub-menu">{{ $lang.view.CreateLicence.phone }}</span>
              <ValidationProvider  rules="" v-slot="{ errors }" v-bind:name="$lang.view.CreateLicence.phone">
                <input class="input-field w3-input" type="text" v-model="phone" >
                <div class="field_error_message">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <br>
            <input type="checkbox" id="permission" v-model="user_validation_rights"/><label for="permission"/>
            <span style="padding-left: 30px;" class="validation">{{ $lang.view.CreateLicence.validation_msg }}</span>
            <br>

            <div style="padding-top: 50px; padding-left: 50px;">
              <Button v-if="!invalid && dirty" @click.native="createLicence()" :text='$lang.view.CreateLicence.create' :icon="plus_sign_img" :clicked_icon="plus_sign_clicked_img" class='wide' />
              <Button v-else :text='$lang.view.CreateLicence.create' :icon="plus_sign_img" class='wide inactive' />
            </div>

            <!-- error message -->
            <div v-if="error_message" class="w3-panel w3-red">
              {{error_message}}
            </div>

        </ValidationObserver>
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Button from '@/components/Button.vue'
import PlusSignImage from '@/assets/images/common/Plus.svg'
import PlusSignClickedImage from '@/assets/images/common/Plus-survol.svg'
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'CreateLicence',
  data () {
    return {
      plus_sign_img: PlusSignImage,
      plus_sign_clicked_img: PlusSignClickedImage,
      error_message: null,
      name: null,
      email: null,
      phone: null,
      user_validation_rights: true,
      regex: /^[A-zÀ-ú\d-_ ]*$/,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide,
    Button,
    ValidationProvider,
    ValidationObserver
  },
  mounted () {
  },
  created: function () {
    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
  },
  methods: {
    async createLicence () {
      var request = {
        name: this.name,
        client_name: this.name,
        client_contact_name: this.name,
        client_contact_email: this.email,
        client_contact_phone: this.phone,
        project_manager_id: -1,
        sales_manager_id: -1,
        ordered_project_number: 0, // TODO
        max_project_size: 100, // TODO : dans la webapi
        agency_user_id: this.$store.getters.userId,
        application_type: 'MaSolutionVideo'
      }

      var resp = null
      try {
        resp = await this.$http({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: '/licences/',
          data: request
        })
      } catch (error) {
        if (error) this.error_message = error.response.data.message
        return
      }

      if (resp.status !== 201) {
        this.error_message = resp.response.data.message // Unable to create licence
        return
      }

      var newLicence = resp.data
      request = {
        login: newLicence.id + '-' + newLicence.user_counter,
        name: this.name,
        email: this.email,
        phone: this.phone,
        type: 'LICENCE_USER',
        licence_id: newLicence.id,
        validation_rights: this.user_validation_rights,
        application_type: 'MaSolutionVideo'
      }

      try {
        resp = await this.$http({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.$store.state.config.webapiurl,
          url: '/users/',
          data: request
        })
      } catch (error) {
        if (error) this.error_message = 'Error :' + error.response.data.message
        return
      }

      if (resp.status === 201) {
        var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
        this.$router.push(`/login/${agencyId}/folders`)
      } else if (resp.response.data.code === 502) {
        this.error_message = this.$lang.component.user_edit.sending_mail_error
      } else {
        this.error_message = 'Error : code=' + resp.response.data.code + ', message=' + resp.response.data.message
      }
    },

    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

.sub-menu {
  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

.input-field {
  background-color: #FFFFFF;
  border-radius: 6px;
  width: 430px;
  height: 55px;
}

.wide {
  width: 280px;
  cursor: pointer;
}

.inactive {
  border-color: grey;
  background-color: grey;
  cursor:default;
}

.inactive:hover {
  border-color: grey;
  background-color: grey;
  color: #FFFFFF;
  cursor:default;
}

.validation {
  color: #0C4168;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

input[type=checkbox] {
  display:none;
}

input[type=checkbox] + label {
  border: 1px solid #0C4168;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display:inline-block;
  background-size: 100%;
  background-color: #FFFFFF;
}

input[type=checkbox]:checked + label {
  background-color: #009CCA;
}

</style>
