<template>
  <div id="container" @click="hideErrorPanel">

    <!-- Navigation menu -->
    <div id="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

    <!-- main area -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $lang.view.EditLicence.title }}</strong></span>
        <hr>
      </div>

      <div style="display: flex;">
        <!-- project category list -->
        <div style="width: 32%; margin-right: 2%;">
          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_created.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="licenceLink" class="routerlink">
                {{ $lang.view.EditLicence.project }}<br/>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_created_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_editing.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editingLink" class="routerlink">
                {{ $lang.view.EditLicence.editing }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_editing_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_edited.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="editedLink" class="routerlink">
                {{ $lang.view.EditLicence.edited }} <br>
                <span style="color: #FFA8A2; font-size: 16px;">{{ nb_edited_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

          <div class="dossier" style="background-color: #2E895F; color: #FFFFFF;">
            <div style="float: left; display: flex; align-items: center; padding-left: 10px;">
              <img src="../assets/images/common/Project_validated_clicked.svg" width="36px" height="39px" />
            </div>
            <div style="display: flex; flex-grow: 1; align-items: center;">
              <router-link v-bind:to="validatedLink" class="routerlink">
                {{ $lang.view.EditLicence.validated }} <br>
                <span style="font-size: 16px;">{{ nb_validated_projects }} element(s)</span>
              </router-link>
            </div>
          </div>

        </div>

        <!-- Validated projects list of a licence -->
        <div v-if="this.projects_list.length == 0" class="projects" style="display: flex; align-items: center; justify-content: center; width: 66%;">
          <div style="width: 270px;"><strong>{{ $lang.view.EditLicence.no_validated_project }}</strong></div>
        </div>
        <div v-else class="projects" style="width: 66%;">
          <div style="flex: 1; max-height: 350px; overflow-y: auto;">

            <div class="project" v-for="(project, index) in projects_list" :key="index" style="display: flex; align-items: center; padding-left: 10px;">
              <div style="float: left; flex-grow: 1;">
                <img style="margin-right: 10px;" src="../assets/images/common/Project_validated.svg" width="34px" height="37px" />
                <span class="pointer" @click="playVideo(project)" :title="$lang.view.ListValidatedProjects.play"> {{ project.name }} </span>
              </div>
              <div style="float: right; display: flex; margin-right: 20px;">
                <HoverButton
                    style="margin-right: 20px;"
                    :img="download_img"
                    :clicked_img="download_clicked_img"
                    :img_title="$lang.view.ListValidatedProjects.download"
                    img_width="25px"
                    img_height="25px"
                    v-on:on-clicked-button="downloadEditedVideo(project)"
                  >
                </HoverButton>

                <HoverButton
                    :img="copy_img"
                    :clicked_img="copy_clicked_img"
                    :img_title="$lang.view.ListValidatedProjects.copy_to_clipboard"
                    img_width="25px"
                    img_height="25px"
                    v-on:on-clicked-button="copyToClipboardProjectEditedVideoURL(project)"
                  >
                  </HoverButton>
              </div>
            </div>

          </div>
        </div>
        <LoadingAnimation v-if="loading" :progressEvent="progressEvent" />
        <Snackbar v-if="loading" v-model="show_download_snackbar" :text="$lang.view.ListValidatedProjects.downloading"></Snackbar>
        <Snackbar v-if="loading" v-model="show_copy_done_snackbar" :text="$lang.view.ListValidatedProjects.copy_done"></Snackbar>

      </div>

      <!-- error message -->
      <div id="page-mask" v-if="error_message"></div>
      <div ref="error_panel" id="error_panel" v-if="error_message" class="w3-panel w3-red">
        {{error_message}}
      </div>

    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import HoverButton from '@/components/HoverButton.vue'
import copyImage from '../assets/images/common/Copier.svg'
import copyClickedImage from '../assets/images/common/Copier-survol.svg'
import downloadImage from '../assets/images/common/Telecharger.svg'
import downloadClickedImage from '../assets/images/common/Telecharger-survol.svg'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ListValidatedProjects',
  data () {
    return {
      loading: false,

      projects_list: [],
      foldersLink: null,
      licenceLink: null,
      editingLink: null,
      editedLink: null,
      validatedLink: null,

      copy_img: copyImage,
      copy_clicked_img: copyClickedImage,
      download_img: downloadImage,
      download_clicked_img: downloadClickedImage,
      progressEvent: null,

      show_download_snackbar: false,
      show_copy_done_snackbar: false,

      error_message: null,
      succes_message: null,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide,
    HoverButton,
    LoadingAnimation,
    Snackbar
  },
  created: function () {
    this.foldersLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/folders`
    this.licenceLink = `/login/${this.$route.params.agencyId}-${this.$route.params.agencyName}/${this.$route.params.licenceId}-${this.$route.params.licenceName}`
    this.editingLink = this.licenceLink + '/editing'
    this.editedLink = this.licenceLink + '/edited'
    this.validatedLink = this.licenceLink + '/validated'

    this.getProjectList()
  },
  mounted () {
    this.reloadCurrentLicence()
  },
  beforeDestroy () {
  },
  methods: {
    hideErrorPanel () {
      this.error_message = null
    },

    getVideoURL (project) {
      var path = project.download_url + '/' + project.edited_file_url
      var downloadUrl = path.replace(/[\\]+/g, '/')
      return downloadUrl
    },

    getProjectList () {
      var request = {
        licence_id: this.$store.getters.currentLicence.id,
        state: 'validated',
        limit: 500, // TODO
        sort: [['name', 'ASC']]
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/projects/list',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.projects_list = resp.data
            return resp.data
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    reloadCurrentLicence () {
      var licenceId = this.$store.getters.currentLicence.id

      this.$http({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: `/licences/${licenceId}`,
        data: {}
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            var licence = resp.data
            await this.$store.dispatch('setCurrentLicence', licence)
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    copyToClipboardProjectEditedVideoURL (project) {
      var downloadUrl = this.getVideoURL(project)
      var el = document.createElement('textarea')
      el.value = downloadUrl
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.show_copy_done_snackbar = true
    },

    async playVideo (project) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = this.$route.params.licenceId + '-' + this.$route.params.licenceName
      var projectID = project.id + '-' + project.name

      await this.$store.dispatch('setCurrentProject', project)
      this.$router.push(`/login/${agencyId}/${licenceId}/${projectID}/playvideo`)
    },
    downloadEditedVideo (project) {
      this.show_download_snackbar = true
      this.loading = true

      var request = {
        file_url: project.edited_file_url
      }
      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        responseType: 'arraybuffer',
        url: '/projects/' + project.id + '/file',
        data: request,
        onDownloadProgress: (progressEvent) => {
          this.progressEvent = progressEvent
        }
      })
        .then((resp) => {
          if (resp.status === 200) {
            var filename = project.edited_file_url.split('\\').pop().split('/').pop()
            let blob = new Blob([resp.data], { 'type': 'application/octet-stream' })
            this.progressEvent = null
            this.downloadFile(blob, filename)
          } else {
            this.loading = false
          }
        })
        .catch((_error) => {
          this.loading = false
          this.error_message = this.$lang.view.PlayVideo.no_video_link
        })
    },

    downloadFile (blob, filename) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        let fileURL = window.URL.createObjectURL(blob)
        let fileLink = document.createElement('a')
        fileLink.setAttribute('target', '_blank')
        fileLink.setAttribute('href', fileURL)
        fileLink.setAttribute('download', filename)
        fileLink.style.visibility = 'hidden'
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
        window.URL.revokeObjectURL(fileURL)
        fileLink.remove()
      }
      this.loading = false
    }
  },
  computed: {
    nb_created_projects () {
      return this.$store.getters.currentLicence.created_project_counter
    },
    nb_editing_projects () {
      return this.$store.getters.currentLicence.editing_project_counter
    },
    nb_edited_projects () {
      return this.$store.getters.currentLicence.edited_project_counter
    },
    nb_validated_projects () {
      return this.$store.getters.currentLicence.validated_project_counter
    },
    nb_credits () {
      return this.$store.getters.currentLicence.credits_counter
    },
    userType () {
      return this.$store.getters.userType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}

.dossier {
  display: flex;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  height: 90px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 25px;
  text-align: left;
}

.projects {
  display: flex;
  flex-direction:column;
  margin-left: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  height: 420px;
  width: 100%;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 27px;
  text-align: center;
}

.project {
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D2D5E1;
  border-radius: 4px;
  width: 100%;
  height: 70px;

  color: #0C4168;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

#page-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#error_panel {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 600px;
  margin-left: -300px;

  height: 140px;
  margin-top: -70px;
}

</style>
