// messages.js
module.exports = {

  view: {

    Login: {
      title: 'MON ESPACE MY TYDEO',
      welcome1: '<strong>Bienvenue dans votre espace personnel</strong>,',
      welcome2: 'vous devez <strong>\u00EAtre identifi\u00E9 pour acc\u00E9der</strong> \u00E0 vos documents et vid\u00E9os !',
      login: 'Identifiant',
      password: 'Mot de passe',
      connection: 'Connexion',
      wrong_access: 'Vous n\'avez pas les droits d\'acces pour ce service',
      no_agency: 'Votre licence n\'est liée à aucune agence',
      login_error: 'Identifiant ou mot de passe incorrect',
      lost_password: 'mot de passe oubli\u00E9',
      lost_login: 'identifiant oubli\u00E9',
      accept_message: 'En continuant à naviguer sur ce site, vous agréez à l’utilisation de cookies en vue de réaliser...',
      accept_button: 'J\'accepte',
      close: 'Fermer',
      invalid_token: 'Votre session a expiré, veuillez vous identifier',
      not_registered_yet: 'Pas encore inscrit ?',
      discover: 'Découvrez My Tydeo !'
    },

    Cgu: {
      refuse: 'Refuser',
      accept: 'Accepter'
    },

    InitPassword: {
      title: 'Renseigner le mot de passe',
      password: 'Mot de passe',
      back: 'Retour',
      send: 'Envoyer',
      new_password: 'nouveau mot de passe',
      confirm_password: 'Confirmation mot de passe',
      passwords_not_equal: 'Les mots de passe ne sont pas identiques',
      confirmation_message_created: 'Votre mot de passe a \u00E9t\u00E9 cr\u00E9\u00E9',
      confirmation_message_updated: 'Votre mot de passe a \u00E9t\u00E9 modifi\u00E9',
      password_too_short: 'Le mot de passe doit comporter au moins 8 caract\u00E8res',
      token_expired_message1: 'Vous ne pouvez pas r\u00E9initialiser votre mot de passe (le d\u00E9lai a expir\u00E9).',
      token_expired_message2: 'Vous pouvez refaire une demande de renouvellement de mot de passe.',
      visit: 'Ou rendez-vous sur votre ',
      web: 'plateforme web',
      download_app: 'Téléchargez l\'application mobile',
      edit: '\u00E0 renseigner'
    },

    ResetPassword: {
      title: 'Changer le mot de passe',
      password: 'Mot de passe',
      confirm_password: 'Confirmation mot de passe',
      send: 'Envoyer',
      edit: '\u00E0 renseigner'
    },

    LostPassword: {
      title: 'Vous avez oublié votre <strong>mot de passe</strong> ?',
      login: 'Veuillez saisir votre <strong>identifiant</strong>',
      login_edit: 'identifiant du compte',
      confirmation_message: 'Un email vous a \u00E9t\u00E9 envoy\u00E9 avec un lien pour changer votre mot de passe',
      unknown_login: 'Aucun compte associ\u00E9 \u00E0 ce login',
      send: 'Envoyer'
    },

    CheckPassword: {
      message1: 'Rendez-vous dans votre <strong>bo\u00EEte mail<strong>',
      message2: 'Vous allez recevoir un lien pour r\u00E9initialiser votre <strong>mot de passe</strong>',
      message3: '(V\u00E9rifiez aussi dans vos spams)',
      back: 'Retour'
    },

    LostId: {
      title: 'Vous avez oublié <strong>votre identifiant</strong> ?',
      email: 'Veuillez saisir votre adresse email',
      email_edit: 'e-mail du compte',
      confirmation_message: 'Un email vous a \u00E9t\u00E9 envoy\u00E9 avec vos identifiants',
      unknown_email: 'Aucun compte associ\u00E9 \u00E0 cet email',
      send: 'Envoyer'
    },

    CheckId: {
      message1: 'Rendez-vous dans votre <strong>bo\u00EEte mail</strong>',
      message2: 'Vous allez recevoir votre <strong>identifiant</strong>',
      message3: '(V\u00E9rifiez aussi dans vos <strong>SPAMs</strong>)',
      back: 'Retour au site'
    },

    EditMyInfo: {
      title: 'Mes Infos',
      customize: 'Personnaliser l’affichage',
      account: 'Compte',
      policy: 'Politique de confidentialité',
      accountname: 'Nom du compte',
      terms_of_use: 'Conditions g\u00E9n\u00E9rales d\'utilisation',
      private_policies: 'Politique de confidentialité',
      import_message: 'Important pour vous et pour nous',
      remaining_credits: 'cr\u00E9dits restants',
      close: 'Fermer',
      disconnect: 'Déconnexion',
      moreInfos: 'Plus d\'informations'
    },

    Tutos: {
      title: 'Ressources',
      download: 'Télécharger le fichier',
      copy_to_clipboard: 'Copier le lien dans le presse-papier'
    },

    ListLicences: {
      new: 'Nouvel utilisateur',
      title: 'Mes dossiers',
      name: 'Nom',
      no_licence: 'Vous n\'avez pas de licence pour l\'instant',
      nb_project: 'Projets',
      nb_editing: 'Montages en cours',
      nb_edited: 'Validations en attente',
      nb_validated: 'Vid\u00E9os valid\u00E9es',
      nb_credits: 'Cr\u00E9dits',
      create_folder: 'Cr\u00E9er un nouveau dossier',
      delete_warning0: 'Souhaitez-vous supprimer le dossier ',
      delete_warning1: 'Toute suppression sera définitive !',
      yes: 'Oui',
      no: 'Non',
      warning: 'Attention'
    },

    CreateLicence: {
      title: 'Créer un compte utilisateur',
      name: 'Nom de l\'utilisateur',
      email: 'Email utilisateur',
      phone: 'Tél Mobile utilisateur',
      validation_msg: 'Droit de validation',
      create: 'Créer l\'utilisateur'
    },

    EditLicence: {
      title: 'Mes projets',
      project: 'Projets',
      editing: 'Montages en cours',
      edited: 'Validations en attente',
      validated: 'Vidéos validées',
      login_header: 'Login',
      email_header: 'E-mail',
      rights_header: 'Droits de validation',
      delete_warning0: 'Souhaitez-vous supprimer le projet ',
      delete_warning1: 'Toute suppression sera définitive !',
      yes: 'Oui',
      no: 'Non',
      warning: 'Attention',
      new_project: 'Nouveau projet',
      no_project: 'Vous n\'avez pas de projet pour l\'instant',
      no_editing_project: 'Vous n\'avez pas de projet en cours de montage pour l\'instant',
      no_edited_project: 'Vous n\'avez pas de projet en attente de validation pour l\'instant',
      no_validated_project: 'Vous n\'avez pas de projet validé pour l\'instant',

      new_project_title: 'Nouveau Projet',
      projectname: 'Nom du projet',
      create_project: 'Créer le projet',
      placeholder: 'Veuillez saisir le nom du nouveau projet'
    },

    ListValidatedProjects: {
      title: 'Vidéos validées',
      name: 'nom du projet',
      play: 'Jouer la vidéo',
      download: 'Télécharger la vidéo validée',
      copy_to_clipboard: 'Copier le lien dans le presse-papier',
      downloading: 'Téléchargement en cours',
      copy_done: 'Le lien de votre vidéo a bien été copié'
    },

    EditProject: {
      my_folders: 'Mes dossiers',
      my_projects: 'Mes projets',
      my_editing: 'Montages en cours',
      my_edited: 'Validations en attente',
      my_validated: 'Vidéos validées',
      brief: 'Briefez-nous',
      contents: 'Mes éléments',
      add_elements: 'Ajoutez les éléments',
      update: 'Modifier',
      brief_placeholder: 'vos notes sur le montage à faire...',
      brief_saved: 'brief sauvegardé',
      brief_dirty: 'Attention votre brief n\'a pas été sauvegardé.\nCliquez sur \'Annuler\' puis \'sauvegarder\' pour l\'enregistrer.\nSinon cliquez sur \'OK\'.',
      information: 'Envoyer mon projet en montage',
      sent_confirmed: 'Votre projet a bien été envoyé.',
      ok: 'Ok',
      cancel: 'Annuler',
      before_sending: 'Attention, vous ne pourrez plus ajouter d’éléments à ce projet.',
      save_brief: 'Sauvegarder',
      send_project: 'Envoyer en montage',
      before_edit_rush_name_information: 'Renommer un contenu',
      before_edit_rush_name_message: 'Veuillez saisir le nouveau nom du contenu et cliquer sur OK.',
      before_delete_rush_information: 'Supprimer un contenu',
      before_delete_rush_message: 'Voulez-vous supprimer le contenu',
      empty_file: 'Erreur : le fichier est vide',
      brief_brief: 'Renseignez précisément les informations dont nous aurons besoin pour réaliser votre vidéo.',
      contents_brief: 'Merci de déposer vos documents dans l\'espace prévu ci-dessous'
    },

    EditingProject: {
      brief: 'brief',
      contents: 'contenus',
      update: 'Modifier',
      brief_placeholder: 'vos notes sur le montage à faire...'
    },

    ValidateVideo: {
      title: 'à valider',
      brief_placeholder: 'Renseigner les modifications ici',
      warning: 'Vous y êtes presque',
      validate: 'Valider la vidéo',
      validate_warning: 'Confirmez-vous la validation du projet ?',
      yes: 'Oui',
      no: 'Non',
      modify_project: 'Demander une modification',
      information: 'Information',
      ok: 'Ok',
      modification_confirmed: 'Votre demande a bien été enregistrée. Nous la traitons dans les meilleurs délais.',
      send: 'Envoyer les modifications',
      your_request: 'Votre demande',
      success: 'Bravo !',
      validate_success: 'Vous pouvez désormais la télécharger.',
      public_link: 'Créer un lien public de visionnage',
      public_link_copied: 'Lien copié dans le presse-papier'
    },

    PlayVideo: {
      title: 'Lire la vidéo',
      no_video_link: 'Votre vidéo a été envoyée il y a plus d\'un mois, le lien est expiré. Si vous souhaitez la visualiser de nouveau, merci de contacter votre référent My Tydeo.'
    }

  },

  component: {

    NavigationMenu: {
      folders: 'Mes dossiers',
      tutos: 'Ressources',
      infos: 'Mes infos',
      garbage: 'corbeille',
      logout: 'déconnexion'
    },

    UploadRushFiles: {
      cancelMessage: 'Transfert interrompu par l\'utilisateur',
      dropFilesMessage: '<strong>Déposer ou <span class="filepond--label-action">importer</span> vos fichiers ici</strong><br>(documents, vidéos, photos, charte graphique...)'
    },

    UploadLogoFile: {
      cancelMessage: 'Transfert interrompu par l\'utilisateur',
      dropLogoMessage: 'Déposer ou <span class="filepond--label-action">importer</span> votre logo (format jpeg ou png : 50 Ko Max)'
    },

    LoadingAnimation: {
      creating: 'En cours de création ...'
    }

  }
}
