import { render, staticRenderFns } from "./ListLicences.vue?vue&type=template&id=4cb6f767&scoped=true&"
import script from "./ListLicences.vue?vue&type=script&lang=js&"
export * from "./ListLicences.vue?vue&type=script&lang=js&"
import style0 from "./ListLicences.vue?vue&type=style&index=0&id=4cb6f767&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb6f767",
  null
  
)

export default component.exports