<template>
  <div id="container">

    <!-- Connexion side -->
    <div id="connexion_side_content">
      <img id="connexion_side_image" src="@/assets/images/connexion/fond-home.svg" />
    </div>

    <!-- Login area -->
    <div class="login" style="float: left; flex-grow: 1;">

      <div style="float: right; width: 100%; margin-bottom: 150px;">
        <div id="langs">
          <span @click="changeLang('fr')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'fr') }" > <img src="@/assets/images/common/Drapeau-FR.png" alt="Drapeau-FR" width="30px" height="30px"/> </span>
          <span @click="changeLang('en')" v-bind:class="{ 'selected_lang': (this.$store.state.lang == 'en') }" > <img src="@/assets/images/common/Drapeau-Eng.png" alt="Drapeau-Eng" width="30px" height="30px"/> </span>
        </div>
      </div>

      <div style="float: right; width: 100%;">
        <div class="message">
          <div style="margin-top: 70px; margin-left: 50px; width: 500px;" class="w3-container">

              <div v-if="!token_valid">
                <center>
                  <div style="margin-bottom: 10px;">{{ $lang.view.InitPassword.token_expired_message1 }}</div>
                  <div>{{ $lang.view.InitPassword.token_expired_message2 }}</div>
                  <a v-bind:href="reset_link"><Button style="margin-top: 50px; width: 300px;" :text='$lang.view.InitPassword.new_password' :icon="path_right_img" :clicked_icon="path_right_clicked_img" /></a>
                </center>
              </div>
              <div v-else-if="password_changed">
                <center>
                  <span v-if="password_created">
                    {{$lang.view.InitPassword.confirmation_message_created}}
                  </span>
                  <span v-else>
                    {{$lang.view.InitPassword.confirmation_message_updated}}
                  </span>
                  <br>
                  <p v-html="$lang.view.InitPassword.download_app"></p>
                  <p>
                    <a href="https://apps.apple.com/fr/app/my-tydeo/id1498215533" target="_blank" rel="noopener noreferrer"><img :src="ImgAppStore" style="max-height:50px" alt="App Store Video Admin"></a>
                    &nbsp;
                    <a href="https://play.google.com/store/apps/details?id=com.tydeo.mytydeo" target="_blank" rel="noopener noreferrer"><img :src="ImgPlayStore" style="max-height:50px" alt="Play Store Video Admin"></a>
                  </p>
                  <p>{{$lang.view.InitPassword.visit}}<a href="https://connect.mytydeo.com" target="_blank" rel="noopener noreferrer">{{$lang.view.InitPassword.web}}</a></p>
                  <Button style="margin-top: 50px; width: 260px;" :text='$lang.view.InitPassword.back' to="/login" :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
                </center>
              </div>
              <div v-else>
                <div style="margin-bottom: 70px;"> {{ $lang.view.InitPassword.title }}</div>

                <div class="sub_title">{{ $lang.view.InitPassword.password }}</div>
                <input class="w3-input" type="password" v-model="input.password" v-bind:placeholder="$lang.view.InitPassword.edit">

                <div style="margin-top: 50px;"></div>
                <div class="sub_title">{{ $lang.view.InitPassword.confirm_password }}</div>
                <input class="w3-input" type="password" v-model="input.confirmpwd" v-bind:placeholder="$lang.view.InitPassword.edit">

                <center>
                  <Button style="margin-top: 20px; width: 260px;" :text='$lang.view.InitPassword.send' @click.native="initPassword()" :icon="path_right_img" :clicked_icon="path_right_clicked_img" />
                </center>

                <div v-if="error_message" class="w3-panel w3-red">
                  {{error_message}}
                </div>
              </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { localize } from 'vee-validate'

import PathRightImage from '@/assets/images/common/Path-right.svg'
import PathRightClickedImage from '@/assets/images/common/Path-right-survol.svg'
import ImgAppStoreEn from '@/assets/images/common/app_store_en.png'
import ImgAppStoreFr from '@/assets/images/common/app_store_fr.png'
import ImgPlayStoreEn from '@/assets/images/common/play_store_en.png'
import ImgPlayStoreFr from '@/assets/images/common/play_store_fr.png'
import Button from '@/components/Button.vue'

export default {
  name: 'InitPassword',
  data () {
    return {
      input: {
        password: '',
        confirmpwd: ''
      },
      password_changed: false,
      password_created: false,
      token_valid: false,
      reset_link: '',

      path_right_img: PathRightImage,
      path_right_clicked_img: PathRightClickedImage,
      error_message: null
    }
  },
  mounted () {
    this.$store.state.user = null
    this.checkTokenValidity()
    this.reset_link = window.location.origin + window.location.pathname + '#/lostpassword'
  },
  components: {
    Button
  },
  computed: {
    ImgAppStore: function () {
      if (this.$store.getters.lang === 'fr') {
        return ImgAppStoreFr
      } else if (this.$store.getters.lang === 'en') {
        return ImgAppStoreEn
      } else {
        return ImgAppStoreEn
      }
    },
    ImgPlayStore: function () {
      if (this.$store.getters.lang === 'fr') {
        return ImgPlayStoreFr
      } else if (this.$store.getters.lang === 'en') {
        return ImgPlayStoreEn
      } else {
        return ImgPlayStoreEn
      }
    }
  },
  methods: {
    changeLang (lang) {
      this.$setLang(lang)
      this.$store.state.lang = lang
      localize(lang)
    },
    checkTokenValidity () {
      var token = this.$route.params.token
      this.$http({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/users/resetpasswordvalidity/' + token
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.token_valid = true
          } else {
            this.token_valid = false
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
          this.token_valid = false
        })
    },
    initPassword () {
      if (this.input.password !== this.input.confirmpwd) {
        this.error_message = this.$lang.view.InitPassword.passwords_not_equal
        return
      }

      if (this.input.password.length < 8) {
        this.error_message = this.$lang.view.InitPassword.password_too_short
        return
      }

      var request = {
        token: this.$route.params.token,
        password: this.input.password,
        application_type: 'MaSolutionVideo'
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/users/resetpassword',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.password_changed = true
            this.password_created = resp.data.password_created
          } else {
            throw resp
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.login {
  background-color: #F5F2EB;
  height: 100%;
  min-width: 800px;
}

.message {
  float: left;
  margin-left: 150px;
  width: 570px;
  height: 530px;

  background-color: #093555;
  border-radius: 3px;

  color: #FFFFFF;
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0.1px;
  line-height: 27px;
  text-align: center;
}

.sub_title {
  margin-bottom: 10px;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 13px;
  text-align: left;
}

input {
  background-color: #FFFFFF;
  border-radius: 6px;
  width: 420px;
  height: 60px;

  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 13px;
  text-align: left;
}

#langs {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}

#langs span {
  padding: 2px 5px 2px 5px;
  cursor: pointer;
}

#connexion_side_content {
  position: relative;
  overflow: hidden;
  background-color: #093555;

  height: 100%;
  width: 100%;
}

#connexion_side_image {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
}

</style>
