<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav" ref="nav">
      <NavigationMenu input_current_menu='folders'/>
    </div>

      <!-- Licences list -->
      <div id="main" ref="main">

        <div class="top-menu">
          <span> <strong>{{ $lang.view.ListLicences.title }}</strong> </span>
          <hr />
        </div>

        <div v-if="this.licence_list.length == 0" class="no_licence" style="display: flex; align-items: center; justify-content: center; height: 200px;">
          <div style="width: 270px;"><strong>{{ $lang.view.ListLicences.no_licence }}</strong></div>
        </div>
        <div v-else style="max-height: 700px; overflow-y: auto;">
          <table class="tableau td-pointer td-hover" >
            <tr>
              <th style="width: 20%; text-align: left;"> {{ $lang.view.ListLicences.name }} </th>

              <th style="width: 15%;"> {{ $lang.view.ListLicences.nb_project }} </th>

              <th style="width: 15%;"> {{ $lang.view.ListLicences.nb_editing }} </th>

              <th style="width: 15%;"> {{ $lang.view.ListLicences.nb_edited }} </th>

              <th style="width: 15%;"> {{ $lang.view.ListLicences.nb_validated }} </th>

              <th style="width: 10%;"> {{ $lang.view.ListLicences.nb_credits }} </th>

              <th style="width: 10%;"></th>
            </tr>

            <tr v-for="(licence, index) in licence_list" :key="index">
                <td style="width: 20%; text-align: left;" v-on:click="selectLicence(licence)" >
                  <span>
                    <strong> {{ licence.name }} </strong>
                  </span>
                </td>

                <td style="width: 15%;" v-on:click="selectLicence(licence)">
                  <span>
                    {{ licence.created_project_counter }}
                  </span>
                </td>

                <td style="width: 15%;" v-on:click="selectLicenceEditingProjects(licence)">
                  <span>
                    {{ licence.editing_project_counter }}
                  </span>
                </td>

                <td style="width: 15%;" v-on:click="selectLicenceEditedProjects(licence)">
                  <span>
                    {{ licence.edited_project_counter }}
                  </span>
                </td>

                <td style="width: 15%;" v-on:click="selectLicenceValidatedProjects(licence)">
                  <span>
                    {{ licence.validated_project_counter }}
                  </span>
                </td>

                <td style="width: 10%;">
                  <span>
                    {{ licence.credits_counter }}
                  </span>
                </td>

                <td style="width: 10%" @click="showDeleteWarning(licence)">
                  <img src="../assets/images/common/Corbeille.svg" alt="Corbeille"  width="13px" height="15px"/>
                </td>

            </tr>
          </table>
          <hr style=" border: 1px solid #D1D1D1;" />
        </div>

        <!-- Avertissement avant effacement -->
        <div v-if="show_delete_warning == 1" class="w3-modal" style="display: block;">
          <div class="w3-modal-content w3-card-4 w3-margin-bottom w3-animate-top w3-center modal">
            <header class="w3-container">
              <span @click="show_delete_warning = 0" class="w3-button w3-display-topright">&times;</span>
              <h1 class="modal_header">{{$lang.view.ListLicences.warning}}</h1>
            </header>
            <div class="w3-container">
              <br>
              {{ $lang.view.ListLicences.delete_warning0 }} <strong>{{ licence_name_to_delete }}</strong> ?
              <br>
              {{ $lang.view.ListLicences.delete_warning1 }}
              <br><br>
            </div>
            <footer class="w3-container">
              <button class="w3-button w3-margin-right" style="background-color:#eb8c40;"  @click="deleteLicence">{{ $lang.view.ListLicences.yes }}</button>
              <button class="w3-button"  @click="show_delete_warning = 0">{{ $lang.view.ListLicences.no }}</button>
              <br><br>
            </footer>
          </div>
        </div>

        <!-- error message -->
        <div v-if="error_message" class="w3-panel w3-red">
          {{error_message}}
        </div>

      </div>

      <!-- Right side -->
      <div id="right_side" ref="right_side">
        <RightSide />
      </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'

export default {
  name: 'ListLicences',
  data () {
    return {
      error_message: null,

      licence_list: [],

      show_delete_warning: 0,
      licence_id_to_delete: null,
      licence_name_to_delete: null,

      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide
  },
  mounted () {
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.getLicences()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleScroll: function () {
      this.$refs.nav.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.main.style.height = this.$refs.main.scrollHeight + 'px'
      this.$refs.right_side.style.height = this.$refs.main.scrollHeight + 'px'
    },
    handleResize: function () {
      let h = this.$refs.main.scrollHeight
      if (window.innerHeight > h) { h = window.innerHeight }
      this.$refs.nav.style.height = h + 'px'
      this.$refs.main.style.height = h + 'px'
      this.$refs.right_side.style.height = h + 'px'
    },

    showDeleteWarning (licence) {
      this.licence_id_to_delete = licence.id
      this.licence_name_to_delete = licence.name
      this.show_delete_warning = 1
    },

    getLicences () {
      var request = {
        limit: 100000,
        licence_agency_user_id: this.$store.getters.userId,
        sort: [['name', 'ASC']]
      }

      this.$http({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        baseURL: this.$store.state.config.webapiurl,
        url: '/licences/list',
        data: request
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.licence_list = resp.data
            return resp.data
          }
        })
        .catch((error) => {
          if (error) this.error_message = 'Error ' + error.response.status + ': ' + error.response.data.message
        })
    },

    async selectLicence (licence) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = licence.id + '-' + licence.name
      await this.$store.dispatch('setCurrentLicence', licence)
      this.$router.push(`/login/${agencyId}/${licenceId}`)
    },

    async selectLicenceEditingProjects (licence) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = licence.id + '-' + licence.name
      await this.$store.dispatch('setCurrentLicence', licence)
      this.$router.push(`/login/${agencyId}/${licenceId}/editing`)
    },

    async selectLicenceEditedProjects (licence) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = licence.id + '-' + licence.name
      await this.$store.dispatch('setCurrentLicence', licence)
      this.$router.push(`/login/${agencyId}/${licenceId}/edited`)
    },

    async selectLicenceValidatedProjects (licence) {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      var licenceId = licence.id + '-' + licence.name
      await this.$store.dispatch('setCurrentLicence', licence)
      this.$router.push(`/login/${agencyId}/${licenceId}/validated`)
    },

    deleteLicence () {
      this.show_delete_warning = 0

      this.$http({
        method: 'DELETE',
        baseURL: this.$store.state.config.webapiurl,
        url: '/licences/' + this.licence_id_to_delete
      })
        .then((resp) => {
          if (resp.status === 204) {
            this.getLicences()
          } else {
            this.error_message = resp.response.data.message
          }
        })
        .catch((error) => {
          if (error) this.error_message = error.response.data.message
        })
    }

  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';
.top-menu {
  padding-bottom: 5%;

  color: #093555;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 52px;
  text-align: left;
}
.no_licence {
  color: #0C4168;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: 27px;
  text-align: center;
}

.modal {
  background-color: #093555;
  border-radius: 20px;
  width: 600px;
  height: 400px;

  color: #FAF5F5;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.08px;
  text-align: left;
}

.modal_header {
  color: #FAF5F5;
  font-family: DKMagicalBrush;
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.16px;
  line-height: 38px;
  text-align: center;
}

table.tableau {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}
table.tableau th {
    background-color: #F5F2EB;
    border-bottom: 1px solid #D1D1D1;

    color: #0C4168;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 13px;
    text-align: center;
}
table.tableau tr {
    width: 100%;
}
table.tableau tr:nth-of-type(2n) {
  background-color: #F5F2EB;
}
table.tableau tr:nth-of-type(2n+1) {
  background-color: #FFFFFF;
}
table.tableau td {
    padding: 0px;
    color: #003361;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 45px;
    text-align: center;
}
table.td-pointer > tr > td {
    cursor: pointer;
}
table.td-hover > tr > td:hover {
    background: #009CCA;;
}

</style>
