<template>
  <!-- Navigation menu -->
  <div id="navigation_menu">

    <!--
    <div> {{ $route.params.agencyId }} </div>
    <div> {{ $route.params.agencyName }} </div>
    -->

    <div style="width: 100%;">
      <img v-if="logoImg" ref="logo_img1" style="margin: auto; width: 100%; height: 100%; max-width: 200px; display: block;" :src="logoImg" />
    </div>

    <div id="nouveau" v-if="this.$store.getters.userType === 'AGENCY'">
      <Button v-on:click.native="createLicence()" :text='$lang.view.ListLicences.new' :icon="plus_sign_img" :clicked_icon="plus_sign_clicked_img" />
    </div>

    <nav>
      <div style="margin: auto; width: 60%; min-width: 170px; text-align: left;">
        <div v-bind:class="{active_menu: current_menu=='folders'}">
          <img v-if="current_menu=='folders'" src="@/assets/images/navigationmenu/Icone-Listing-active.svg" alt="Icone-Listing" />
          <img v-else src="@/assets/images/navigationmenu/Icone-Listing.svg" alt="Icone-Listing" />
          <router-link v-bind:to="foldersLink" class="routerlink"> {{ $lang.component.NavigationMenu.folders }} </router-link>
        </div><br/>
        <br/>

        <div v-bind:class="{active_menu: current_menu=='tutos'}">
          <img v-if="current_menu=='tutos'" src="@/assets/images/navigationmenu/Icone-tutos-active.svg" alt="Icone-tutos" />
          <img v-else src="@/assets/images/navigationmenu/Icone-tutos.svg" alt="Icone-tutos" />
          <router-link to="/tutos" class="routerlink">  {{ $lang.component.NavigationMenu.tutos }} </router-link>
        </div><br/>
        <br/>

        <div v-bind:class="{active_menu: current_menu=='infos'}">
          <img v-if="current_menu=='infos'" src="@/assets/images/navigationmenu/Icone-mes-infos-active.svg" alt="Icone-mes-infos" />
          <img v-else src="@/assets/images/navigationmenu/Icone-mes-infos.svg" alt="Icone-mes-infos" />
          <router-link to="/myinfos" class="routerlink" >  {{ $lang.component.NavigationMenu.infos }} </router-link>
        </div><br/>
      </div>
    </nav>

  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import PlusSignImage from '@/assets/images/common/Plus.svg'
import PlusSignClickedImage from '@/assets/images/common/Plus-survol.svg'

export default {
  name: 'NavigationMenu',
  props: {
    input_current_menu: String
  },
  components: {
    Button
  },
  data () {
    return {
      current_menu: null,
      plus_sign_img: PlusSignImage,
      plus_sign_clicked_img: PlusSignClickedImage,
      debug: false
    }
  },
  created: function () {
  },
  mounted () {
    this.current_menu = this.input_current_menu
  },
  methods: {
    createLicence () {
      var agencyId = this.$route.params.agencyId + '-' + this.$route.params.agencyName
      this.$router.push(`/login/${agencyId}/createlicence`)
    }
  },
  computed: {
    logoImg () {
      return this.$store.getters.userLogo
    },
    loginInfo () {
      return this.$store.getters.userLogin
    },
    userType () {
      return this.$store.getters.userType
    },
    foldersLink () {
      if (this.$store.getters.userType === 'AGENCY') {
        return `/login/${this.$store.getters.userId}-${this.$store.getters.userLogin}/folders`
      } else {
        var agencyId = this.$store.getters.currentAgency.id + '-' + this.$store.getters.currentAgency.login
        var licenceId = this.$store.getters.currentLicence.id + '-' + this.$store.getters.currentLicence.name
        return `/login/${agencyId}/${licenceId}`
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/global";

#navigation_menu {
  height: 100%;
  width: 100%;

  background-image:url("../assets/images/navigationmenu/star.svg");
  background-repeat: no-repeat;
  background-position: bottom -50px left 0px;
  background-size: 230px 240px;
}

#nouveau {
  padding-top: 8%;
  padding-left: 6%;
  padding-right: 6%;
}

nav {
  padding-top: 8rem;
  padding-left: 10%;
  padding-right: 4%;

  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 22px;
  text-align: left;
}

nav img {
  width: 25px;
  height: 25px;
}

.active_menu {
  color: #009CCA;
}

li a {
  text-decoration: none;
}

</style>
