<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data () {
    return {
      debug: false,
      tokeninterval: null
    }
  },
  methods: {
    async tokenRefresh () {
      if (this.$store.state.token !== null && this.$store.state.token !== '') {
        await this.$store.dispatch('loginToken', { login: this.$store.state.token })
      }
    }
  },
  created: function () {
    // this.$store.state.lang = 'fr'
    /*
    this.$http.interceptors.response.use(undefined, function (err) {
      return Promise.resolve().then(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        return err
      })
    })
    */
  },
  mounted () {
    console.log('NODE_ENV: ' + process.env.NODE_ENV)
    console.log('webapiurl: ' + this.$store.state.config.webapiurl)
    console.log('weburl: ' + this.$store.state.config.weburl)
    console.log('webfile: ' + this.$store.state.config.webfile)

    setTimeout(this.tokenRefresh, 3000)
    setInterval(this.tokenRefresh, 3600000)
  },
  computed: {
  }
}
</script>

<style lang="scss">
@import "./assets/styles/global";

/***/
@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: "Montserrat";
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-Thin.ttf');
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/montserrat/Montserrat-Black.ttf');
}

/***/
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: "Poppins";
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf');
}

/***/
@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-Regular.ttf');
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-Bold.ttf');
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-Light.ttf');
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-Thin.ttf');
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/Poppins-SemiBold/Poppins-Black.ttf');
}

/***/
@font-face {
  font-family: "DKMagicalBrush";
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/dk-magical-brush/DK Magical Brush.otf');
}

html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

</style>
