<template>
  <div id="loading_animation">
    <div class="vertical_centered_modal">
      <div class="vertical_centered_modal_content">
        <div v-if="progressEvent">
          <radial-progress-bar startColor="#009CCA" stopColor="#009CCA" style="margin:auto" :animateSpeed="1" :diameter="200" :completed-steps="percentCompleted" :total-steps="percentTotal">
            <p>{{ percentCompleted }}%</p>
          </radial-progress-bar>
          <p>{{ $lang.component.LoadingAnimation.creating }}</p>
          <p>{{ MBCompleted }}/{{ MBTotal }} MB</p>
        </div>
        <div v-else class="lds-ripple"><div></div><div></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  name: 'LoadingAnimation',
  components: {
    RadialProgressBar
  },
  data: function () {
    return {
      percentTotal: Number(100)
    }
  },
  props: ['progressEvent'],
  computed: {
    percentCompleted: function () {
      return Number(Math.round((this.$props.progressEvent.loaded * 100) / this.$props.progressEvent.total))
    },
    MBCompleted: function () {
      return Number(this.$props.progressEvent.loaded / 1048576).toFixed(2)
    },
    MBTotal: function () {
      return Number(this.$props.progressEvent.total / 1048576).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical_centered_modal {
    z-index: 3;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.vertical_centered_modal_content {
    position: relative;
    padding: 0;
    outline: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

</style>
