'use strict'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage
  })],
  state: {
    current_agency_logo_url: null,
    current_agency: null,
    current_licence: null,
    current_project: null,
    config: {
      publicPath: '',
      webapiurl: '',
      weburl: '',
      webfile: ''
    },
    current_tab: 0,
    status: '',
    token: '',
    user: {}, // {id:, login:, name:, email:, phone:, type:, token: }
    lang: 'fr'
  },
  mutations: {
    /* ************* Begin Authentification management ********************* */
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = { ...payload.user, lang: String(payload.user.lang).toLowerCase() }
      state.lang = String(payload.user.lang).toLowerCase()
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.user = null
      state.current_agency = null
      state.current_licence = null
      state.current_project = null
      state.current_agency_logo_url = null
      state.lang = 'fr'
    },
    /* ************* End Authentification management *********************** */
    current_tab (state, index) {
      state.current_tab = index
    },
    current_agency (state, agency) {
      state.current_agency = agency
    },
    current_licence (state, licence) {
      state.current_licence = licence
    },
    current_project (state, project) {
      state.current_project = project
    },
    current_agency_logo (state, logoUrl) {
      state.current_agency_logo_url = logoUrl
    }
  },
  actions: {
    /* ************* Begin Authentification management ********************* */
    login (context, user) {
      return new Promise((resolve, reject) => {
        context.commit('auth_request')
        // user.application_type = 'MaSolutionVideo'
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.state.config.webapiurl,
          url: '/users/login',
          data: user
        })
          .then(resp => {
            if (resp.status === 200) {
              const token = resp.data.token
              const user = resp.data
              axios.defaults.headers.common['Authorization'] = 'JWT' + ' ' + token
              context.commit('auth_success', { token, user })
              resolve(resp)
            } else {
              throw resp
            }
          })
          .catch(err => {
            context.commit('auth_error')
            reject(err)
          })
      })
    },
    loginToken (context, token) {
      return new Promise((resolve, reject) => {
        context.commit('auth_request')
        // user.application_type = 'MaSolutionVideo'
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          baseURL: this.state.config.webapiurl,
          url: '/users/loginext',
          data: token
        })
          .then(resp => {
            if (resp.status === 200) {
              const token = resp.data.token
              const user = resp.data
              axios.defaults.headers.common['Authorization'] = 'JWT' + ' ' + token
              context.commit('auth_success', { token, user })
              resolve(resp)
            } else {
              throw resp
            }
          })
          .catch(err => {
            context.commit('auth_error')
            reject(err)
          })
      })
    },
    logout (context) {
      return Promise.resolve().then(function () {
        context.commit('logout')
        delete axios.defaults.headers.common['Authorization']
      })
    },
    /* ************* End Authentification management *********************** */
    setCurrentAgency (context, agency) {
      return Promise.resolve().then(function () {
        context.commit('current_agency', agency)
      })
    },
    setCurrentLicence (context, licence) {
      return Promise.resolve().then(function () {
        context.commit('current_licence', licence)
      })
    },
    setCurrentProject (context, project) {
      return Promise.resolve().then(function () {
        context.commit('current_project', project)
      })
    },
    setCurrentAgencyLogo (context, logoUrl) {
      return Promise.resolve().then(function () {
        context.commit('current_agency_logo', logoUrl)
      })
    }
  },
  modules: {
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authToken: state => state.token,
    userLogin: state => {
      if (state.user != null) {
        return state.user.login
      } else {
        return ''
      }
    },
    userType: state => {
      if (state.user != null && state.user.type != null) {
        return state.user.type
      } else {
        return ''
      }
    },
    userId: state => {
      if (state.user != null && state.user.id != null) {
        return state.user.id
      } else {
        return ''
      }
    },
    userValidationRights: state => {
      if (state.user != null && state.user.validation_rights != null) {
        return state.user.validation_rights
      } else {
        return false
      }
    },
    userCguAccepted: state => {
      if (state.user != null && state.user.cgu_accepted != null) {
        return state.user.cgu_accepted
      } else {
        return false
      }
    },
    userLogo: state => {
      return state.current_agency_logo_url
    },
    currentUser: state => {
      return state.user
    },
    currentAgency: state => {
      return state.current_agency
    },
    currentLicence: state => {
      return state.current_licence
    },
    currentProject: state => {
      return state.current_project
    },
    lang: state => {
      return state.lang
    }
  }
})

export default store
