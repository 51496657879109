<template>
  <div id="container">

    <!-- Navigation menu -->
    <div id="nav">
        <NavigationMenu input_current_menu='tutos'/>
    </div>

    <!-- Tutos list -->
    <div id="main">
      <div class="top-menu">
        <span><strong>{{ $lang.view.Tutos.title }}</strong></span>
        <hr />
      </div>
      <div class="fenetre">
        <table class="tableau">
          <tr v-for="(item, index) in tutos_list[$store.state.lang]" :key="index">
              <td style="padding-left: 20px; width: 40%;" >
                    {{ item.title }}
              </td>

              <td style="width: 10%; text-align: right; padding-right: 1.5em;">
                <HoverButton v-if="item.download_url"
                  :img="download_img"
                  :clicked_img="download_clicked_img"
                  :img_title="$lang.view.Tutos.download"
                  img_width="25px"
                  img_height="25px"
                  v-on:on-clicked-button="downloadTutoURL(item.download_url, item.download_filename)"
                >
                </HoverButton>
                <HoverButton v-else-if="item.browse_url"
                  :img="see_img"
                  :clicked_img="see_clicked_img"
                  img_title="Go"
                  img_width="25px"
                  img_height="25px"
                  v-on:on-clicked-button="browseURL(item.browse_url)"
                >
                </HoverButton>
              </td>

          </tr>
        </table>
      </div>
    </div>

    <!-- Right side -->
    <div id="right_side">
      <RightSide />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NavigationMenu from '@/components/NavigationMenu.vue'
import RightSide from '@/components/RightSide.vue'
import HoverButton from '@/components/HoverButton.vue'
import copyImage from '../assets/images/common/Copier.svg'
import copyClickedImage from '../assets/images/common/Copier-survol.svg'
import downloadImage from '../assets/images/common/Telecharger.svg'
import downloadClickedImage from '../assets/images/common/Telecharger-survol.svg'
import voirImage from '../assets/images/common/Voir.svg'
import voirClickedImage from '../assets/images/common/Voir-survol.svg'

export default {
  name: 'Tutos',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      copy_img: copyImage,
      copy_clicked_img: copyClickedImage,
      download_img: downloadImage,
      download_clicked_img: downloadClickedImage,
      see_img: voirImage,
      see_clicked_img: voirClickedImage,

      tutos_list: null,
      debug: 1
    }
  },
  props: {
  },
  components: {
    NavigationMenu,
    RightSide,
    HoverButton
  },
  mounted () {
  },
  created: function () {
    this.tutos_list = {
      'en': [
        {
          title: 'Practical Guide (pdf)',
          download_url: '/pdf/Tuto App MyTydeo - Anglais.pdf',
          download_filename: 'Practical Guide.pdf'
        },
        {
          title: 'Providing a Brief (pdf)',
          download_url: '/pdf/Providing a Brief - v2022.pdf',
          download_filename: 'Providing a Brief.pdf'
        },
        {
          title: 'Talent Release Form (pdf)',
          download_url: '/pdf/Attestation_de_droit_a_l_image.pdf',
          download_filename: 'Talent Release Form.pdf'
        },
        {
          title: 'Online Tutorials',
          browse_url: 'https://www.youtube.com/playlist?list=PLDf-rqfdXZlmGpZTMjsVcLuAPwY1d34xR'
        }
      ],
      'fr': [
        {
          title: 'Filmer avec son smartphone (pdf)',
          download_url: '/pdf/Tuto App MyTydeo.pdf',
          download_filename: 'Filmer avec son smartphone.pdf'
        },
        {
          title: 'Elaborer son brief (pdf)',
          download_url: '/pdf/Elaborer son brief - v2022.pdf',
          download_filename: 'Elaborer son brief.pdf'
        },
        {
          title: 'Attestation de droit à l\'image (pdf)',
          download_url: '/pdf/Attestation_de_droit_a_l_image.pdf',
          download_filename: 'Attestation de droit à l\'image.pdf'
        },
        {
          title: 'Voir nos tutos en vidéo',
          browse_url: 'https://www.youtube.com/playlist?list=PLDf-rqfdXZlmGpZTMjsVcLuAPwY1d34xR'
        }
      ]
    }
  },
  methods: {
    downloadTutoURL (url, filename) {
      const link = document.createElement('a')
      link.download = filename
      link.href = this.$store.state.config.weburl + url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    browseURL (url) {
      window.open(url)
    },

    copyToClipboardTutoURL (url) {
      var el = document.createElement('textarea')
      el.value = this.$store.state.config.weburl + url
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },

    copyToClipboardBrowseURL (url) {
      var el = document.createElement('textarea')
      el.value = url
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },

    isMp4 (path) {
      return path.endsWith('.mp4')
    }

  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/global';

.top-menu {
  padding-bottom: 5%;

  color: #093555;
   font-family: DKMagicalBrush;
   font-weight: normal;
   font-size: 48px;
   letter-spacing: 0.24px;
   line-height: 52px;
   text-align: left;
}

.fenetre {
  color: #0C4168;
  font-family: Poppins-SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.09px;
  line-height: 25px;
  text-align: left;
}

table.tableau {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}

table.tableau tr {
  width: 100%;
  height: 70px;
  background-color: #FFFFFF;
}

</style>
