'use strict'
import Vue from 'vue'
import Lang from 'vue-lang'
import App from './App.vue'
import NoConfigFile from './NoConfigFile.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// vee-validate begin
import { extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import fr from 'vee-validate/dist/locale/fr.json'
// vee-validate end

Vue.config.productionTip = false

/* ************* Begin Authentification management ********************* */

// We can do this.$http and it will be like calling axios directly
Vue.prototype.$http = axios
if (store.getters.authToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'JWT ' + store.getters.authToken
}

/* ************* End Authentification management *********************** */
var locales = {
  'en': require('./lang/en/messages.js'),
  'fr': require('./lang/fr/messages.js')
}

// vee-validate begin
for (let rule in rules) {
  extend(rule, rules[rule])
}
localize({ en, fr })
// vee-validate end

Vue.use(Lang, { lang: 'fr', locales: locales })

/* ******** Load config file dynamically ********************** */
fetch(`config.json?version=${Date.now()}`)
  .then((res) => { return res.json() })
  .then((configJSON) => {
    store.state.config = configJSON
    Vue.prototype.$config = configJSON
    /* global __webpack_public_path__:writable */
    /* exported __webpack_public_path__ */
    // eslint-disable-next-line camelcase
    __webpack_public_path__ = store.state.config.publicPath

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(error => {
    console.log('error: ' + error)
    console.log('Cannot load config.json file')

    new Vue({
      router,
      store,
      render: h => h(NoConfigFile)
    }).$mount('#app')
  })
