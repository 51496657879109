<template>
  <div id="play_video">
    <video id="videoPlayer" class="video-js" width="640px" height="360px" controls preload="auto" data-setup="{}">
      <p class="vjs-no-js">
        Enable javascript to view this video
      </p>
    </video>
  </div>
</template>

<script>
// @ is an alias to /src
import 'video.js/dist/video-js.css'
import _videojs from 'video.js'

const videojs = window.videojs || _videojs

export default {
  name: 'PlayVideo',
  data () {
    return {
      videoUrl: null,
      debug: 1
    }
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
  },
  created: function () {
  },
  mounted () {
    let params = this.$route.params
    this.videoUrl = this.$store.state.config.webfile + '/' + params.agencyId + '-' + params.agencyName + '/' + params.licenceId + '-' + params.licenceName + '/edited/' + params.projectID + '-' + params.projectName
    this.player = videojs('videoPlayer')
    this.player.src({ type: 'video/mp4', src: this.videoUrl })
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault()
    }, false)
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss">
.video-js .vjs-time-control {
    display: block!important;
}
.video-js .vjs-remaining-time {
    display: none!important;
}
</style>

<style lang="scss" scoped>
@import '../assets/styles/global';

#play_video {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: radial-gradient(white, white, grey);
}

.video-js {
  box-shadow: 5px 5px 5px grey;
}
</style>
