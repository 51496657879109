<template>
  <div id="snackbar" :style="errorStyle" :class="{show : show_class_activated}">{{text}} </div>
</template>

<script>
export default {
  name: 'Snackbar',
  data: function () {
    return {
      show_class_activated: false,
      errorStyle: ''
    }
  },
  props: {
    text: String,
    value: Boolean,
    error: Boolean
  },
  mounted: function () {
    if (this.error) {
      this.errorStyle = 'background-color: #C55;'
    }
  },
  watch: {
    value: function (val) {
      if (val === true) {
        // console.log('vrai')
        this.show_class_activated = true
        setTimeout(() => {
          this.show_class_activated = false
          this.$emit('input', false)
          // console.log('cache')
        }, 3000)
      } else {
        // console.log('faux')
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
#snackbar {
  visibility: hidden;
  min-width: 13.5em;
  background-color: rgb(105,136,156);
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 1;

  left: 50%;
  bottom: 20em;

  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.08px;
  text-align: center;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.8s, fadeout 0.8s linear 2s forwards;
  animation: fadein 0.8s, fadeout 0.8s linear 2s forwards;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 20em; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 20em; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 20em; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 20em; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>
